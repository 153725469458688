import React, { useEffect, useContext, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Popup } from '../../core/Popup';
import { Url } from "../../core/Utils";
import { Language } from '../../infrastructure/Languages';
import { CapabilityName } from '../../infrastructure/Constants';
import { CapabilitiesContext, SettingsContext } from '../../infrastructure/Contexts';
import { ApiDataAccess } from "../../infrastructure/ApiDataAccess";
import { QuantitySelectionComponent } from "./QuantitySelectionComponent";


export const ReorderQuantitySelectionUnauthenticated = () => {

    let { reorderIntent } = useParams();

    const popup = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const language = useContext(Language);
    const settings = useContext(SettingsContext);
    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const apiDataAccess = new ApiDataAccess('/api/unauthenticated/reorder');

    const [estimation, setEstimation] = useState();
    const [popupMessage, setPopupMessage] = useState(null);

    const salesforceUri = settings ? settings.settings["Application:SalesforceUri"] : null;
    const skus = location.state ? location.state.selectedSkus : null;

    const {
        handleSubmit,
    } = useForm();

    useEffect(() => {

        const init = async () => {
            try {
                loader.show();
                var request = {
                    reorderIntent: reorderIntent,
                    skus: skus
                };

                var response = await apiDataAccess.post(`/estimate`, request);
                console.log('Estimation', response);
                setEstimation(response);
                loader.hide();
            }
            catch (ex)
            {
                setPopupMessage(language.translateLabel('5de1e4ed-4083-475e-a3ae-6316eea00a08', 'There seems to be a problem retrieving estimation results.', 'Label'));
                popup.current.show();
                //setTimeout(() => {
                //    goBack();
                //}, 3000);
            }
            finally {
                loader.hide();
            }
        }
        if (!salesforceUri) {
            console.log("Missing salesforce url");
            goBack();
        }
        if (!skus || skus.length == 0 || !reorderIntent)
            goBack();
        else
            init();
    }, []);

    const createOffer = async (quantity) => {
        var request = {
            reorderIntent: reorderIntent,
            quantity: quantity,
            skus: skus
        }
        try {
            loader.show();
            var response = await apiDataAccess.post(`/quote`, request);
            console.log(response);
            if (!response || !response.salesforceQuote)
                throw new Error();
            var salesforceCompleteUrl = Url.Normalize(`${salesforceUri}/lightning/r/Quote`);
            var url = `${salesforceCompleteUrl}/${response.salesforceQuote}/view`; // salesforceQuote is case sensitive, normalize method makes all lowercase so it must stay out of that method
            window.location.replace(url)
        }
        catch {
            loader.hide();
            setPopupMessage(language.translateLabel('1f8061e5-0ed9-47eb-9525-edbe28171f19', 'The order can not be created. A used material or feature might have changed or is not available anymore.', 'Label'));
            popup.current.show();
        }
    }

    const goBack = () => {
        navigate(-1);
    }

    return (
        <div>
            {estimation &&
                <QuantitySelectionComponent estimation={estimation} skuCount={skus.length} onSubmit={(quantity) => createOffer(quantity)} onCancel={() => { goBack() }} />
            }
            <Popup closeOnClickAway={true} ref={popup}>
                <div className='contentSection'>
                    {popupMessage}
                </div>
            </Popup>
        </div>
    );
};
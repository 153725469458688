import React, { useState, useRef, useContext } from "react";
import { EditLink } from "../../core/EditLink";
import { Confirmation } from "../../core/Confirmation";
import { Textbox } from "../../core/Textbox";
import { MessageLink } from "../../core/MessageLink";

import "./OffsetEditor.css";
import { Language } from "../../infrastructure/Languages";

export const OffsetEditor = ({ errorMessage, title, offset, hasError, disabled, onChanged }) => {
    const language = useContext(Language);

    const getUom = (offset) => (offset.uomId === 15 ? "mm" : "in");

    const confirmationRef = useRef(null);
    const getValue = (offset) => offset.value;

    const [value, setValue] = useState(getValue(offset));
    const [currentInput, setCurrentInput] = useState(getValue(offset));
    const [showPopup, setShowPopup] = useState(false);

    const uom = getUom(offset);

    const onInputValueChanged = (value, valid) => {
        if (confirmationRef.current) {
            confirmationRef.current.enableSaveButton(valid);
        }

        setCurrentInput(value);
    };

    const onSaveButtonClicked = () => {
        setValue(currentInput);
        setShowPopup(false);
        console.log("value:", value, " currentInput:", currentInput);

        if (onChanged) {
            onChanged(currentInput, offset.uomId);
        }
    };

    const getErrorMessage = () => {
        switch (errorMessage) {
            case "location.zipper":
                return language.translateLabel(
                    "760efd2d-7ca6-4ceb-b474-edc82064ec1b",
                    "The location of the zipper is not valid"
                );

            case "location.hanghole":
                return language.translateLabel(
                    "fd1cc329-b505-4792-8edf-ad7c363fd014",
                    "The location of the hang hole is not valid"
                );

            case "location.tearnotch":
                return language.translateLabel(
                    "abcff2e4-1c55-4f1f-aa60-5ef8b9657961",
                    "The location of the Tear Notch is not valid"
                );

            case "location.valve":
                return language.translateLabel(
                    "24448cb7-f132-45e9-92ac-f793e70e57c1",
                    "The location of the Valve is not valid"
                );
            case "conflict.zipper.tearnotch":
            case "conflict.tearnotch.zipper":
                return language.translateLabel(
                    "c46d7d5d-8d94-42d9-b2bb-cded59ba1bfc",
                    "Tear Notch and Zipper cannot overlap"
                );
            case "conflict.zipper.hanghole":
            case "conflict.hanghole.zipper":
                return language.translateLabel(
                    "af68d0ce-28a4-481a-8264-be2466814512",
                    "Hang Hole and Zipper cannot overlap"
                );

            case "conflict.zipper.valve":
            case "conflict.valve.zipper":
                return language.translateLabel(
                    "b77f65cf-f460-41d6-854f-b259cc165846",
                    "Valve and Zipper cannot overlap"
                );

            case "conflict.tearnotch.hanghole":
            case "conflict.hanghole.tearnotch":
                return language.translateLabel(
                    "68fa0fb0-b51b-4bcf-a96b-ffb9d4be64b6",
                    "Hang Hole and Tear Notch cannot overlap"
                );

            case "conflict.tearnotch.valve":
            case "conflict.valve.tearnotch":
                return language.translateLabel(
                    "3d24a63c-76cf-473d-a60a-d6d8ecc533c7",
                    "Valve and Tear Notch cannot overlap"
                );

            case "conflict.hanghole.valve":
            case "conflict.valve.hanghole":
                return language.translateLabel(
                    "1665f286-0dcd-40f7-9b52-2c35b3c35e76",
                    "Valve and Hang Hole cannot overlap"
                );

            case "offset.zipper":
            case "offset.hanghole":
            case "offset.tearnotch":
            case "offset.valve":
                return language.translateLabel(
                    "fc8afda7-2ae3-4231-86e6-b474a28997e4",
                    "Distance from top of the pouch is too large"
                );

            case "sequence.zipper.hanghole":
                return language.translateLabel(
                    "1a1b8d53-0b9e-4229-9305-997d7d8d5ffe",
                    "Swap Zipper and Hanghole location"
                );

            case "sequence.zipper.tearnotch":
                return language.translateLabel(
                    "77b11a01-a2b3-4b2b-a6ae-8152390d9948",
                    "Swap Zipper and Tear Notch location"
                );

            case "sequence.zipper.valve":
                return language.translateLabel(
                    "0610bbe0-97ba-4d89-b06b-1e7ed25796fb",
                    "Swap Zipper and Valve location"
                );

            case "sequence.hanghole.zipper":
                return language.translateLabel(
                    "9bda5d3e-dfac-4b55-86f9-c45e2be80a45",
                    "Swap Hanghole and Zipper location"
                );

            case "sequence.hanghole.tearnotch":
                return language.translateLabel(
                    "ee3b6eb8-6886-4b09-bcaf-bbb425b9cfac",
                    "Swap Hanghole and Tear Notch location"
                );

            case "sequence.hanghole.valve":
                return language.translateLabel(
                    "42c47d16-df38-421a-a4c2-248cdba36528",
                    "Swap Hanghole and Valve location"
                );

            case "sequence.tearnotch.zipper":
                return language.translateLabel(
                    "a5b065c5-df47-40ae-a5b0-dc95c0c58d09",
                    "Swap Tear Notch and Zipper location"
                );

            case "sequence.tearnotch.hanghole":
                return language.translateLabel(
                    "57208acf-2aa5-4d79-93f9-dda9805bfae3",
                    "Swap Tear Notch and Hanghole location"
                );

            case "sequence.tearnotch.valve":
                return language.translateLabel(
                    "a581ca43-1b17-45fa-895f-ff63a734b9b9",
                    "Swap Tear Notch and Valve location"
                );

            case "sequence.valve.zipper":
                return language.translateLabel(
                    "a2da5a4f-02c9-4641-93c3-bbdca4e6db96",
                    "Swap Valve and Zipper location"
                );

            case "sequence.valve.hanghole":
                return language.translateLabel(
                    "5177d839-a0a4-415b-848e-a58ee6fb7600",
                    "Swap Valve and Hanghole location"
                );

            case "sequence.valve.tearnotch":
                return language.translateLabel(
                    "f51eebf4-0c4c-4396-910a-5e5f045000e2",
                    "Swap Valve and Tear Notch location"
                );

            default:
                return <span>{errorMessage}</span>;
        }
    };

    return (
        <div className="offsetEditor">
            <div>
                {hasError ? (
                    <div>
                        <MessageLink
                            type="error"
                            onClick={() => {
                                setShowPopup(true);
                            }}
                        >
                            {value} {uom}
                        </MessageLink>{" "}
                        <span className="font-size-sm error">{getErrorMessage()}</span>
                    </div>
                ) : (
                    <div>
                        <EditLink
                            disabled={disabled}
                            onClick={() => {
                                setShowPopup(true);
                            }}
                        >
                            {value} {uom}
                        </EditLink>{" "}
                        <span className="font-size-sm">Distance from top of pouch</span>
                    </div>
                )}

                {showPopup && (
                    <>
                        <Confirmation
                            ref={confirmationRef}
                            onCancelClick={() => {
                                setShowPopup(false);
                            }}
                            onSaveClick={onSaveButtonClicked}
                        >
                            <h3>{title}</h3>
                            <p>
                                {language.translateLabel(
                                    "fab68029-a520-4a54-8c8d-18b8f90125fb",
                                    "Please enter the offset"
                                )}
                                ({uom}):
                            </p>
                            <Textbox
                                type="number"
                                value={currentInput}
                                regex={"[0-9]*(.[0-9]{0,4})?"}
                                required={true}
                                requireValidInput={true}
                                onChange={onInputValueChanged}
                            />
                        </Confirmation>
                    </>
                )}
            </div>
        </div>
    );
};

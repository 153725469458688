import React, { useState, useContext, useEffect, useRef } from "react";
import { CapabilityName, ComponentType, MeasureUnit } from "../../infrastructure/Constants";
import { ShoppingBreadcrumbs, OrderReference } from '../../core/Breadcrumbs';
import { Card } from "../../core/Card";
import { Row } from "../../core/Row";
import { Column } from "../../core/Column";
import { Alert } from "../../core/Alert";
import { SectionLabel } from "../../core/SectionLabel";
import { SectionTitle } from "../../core/SectionTitle";
import { DownloadLink } from "../../core/DownloadLink";
import { OffsetEditor } from "../../components/offer/OffsetEditor";
import { RollRestrictionEditor } from "../../components/offer/RollRestrictionEditor";
import { UnwindDirectionDropdown } from "../../components/offer/UnwindDirectionDropdown";
import { DielineImage, ErrorCodes, ErrorCode } from "../../components/offer/DielineImage";
import { ExistingOfferThreeDModel } from "../../components/ExistingOfferThreeDModel";
import { FeatureLocationType, CategoryType, ProductMetric } from "../../core/offer/Constants";
import { SalesChannel } from "../../infrastructure/Constants";
import "./ProductSpecs.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Language } from "../../infrastructure/Languages";
import OrderPlacementApi from "../../infrastructure/OrderPlacementApi";
import { useGlobalOfferContext, useGlobalOfferId, useOrderPlacementRouter } from "../../infrastructure/GlobalOfferId";
import { useForm, get } from "react-hook-form";
import { ExpandableCard } from "../../components/offer/ExpandableCard";
import { CapabilitiesContext } from "../../infrastructure/Contexts";
import { Translation } from "../../core/Translation";
import { useTracking } from "react-tracking";
import { useCookies } from 'react-cookie';
import Carousel from "nuka-carousel"
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication, useMsal, useAccount } from "@azure/msal-react";
import { loginRequest, protectedResources } from "../../authConfig";
import "./ProductSpecs.css";
import { Popup } from "../../components/offer/Popup";
import { RegisterSignInPopup } from "../../components/offer/RegisterSignInPopup";




export const ProductSpecs = ({ redirect, route, initialized, offer, salesforceAccount,
    orderReference, formattedSizeString, status, analyticsMetadata, globalOffer, isOrderPlacementEnabled }) => {

    const { trackEvent } = useTracking({ page: "ProductPage" });

    const { saveContext, offerContext, loadOfferContextStatus } = useGlobalOfferContext(globalOffer.globalOfferId);

    const [loaded, setLoaded] = useState(false);

    const language = useContext(Language);
    //const [offer, setOffer] = useState(null);
    const [threeDError, setThreeDError] = useState(false);
    const [features, setFeaturesValue] = useState("");
    const [feature, setFeatureValue] = useState("");
    const [changeOffsetDisabled, setChangeOffsetDisabled] = useState(false);
    const [key, setKey] = useState("");
    const [offset, setOffset] = useState(null);
    const api = new OrderPlacementApi("/");
    const navigate = useNavigate();
    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const isReorder = !!offer.reorderDetails;

    const [cookies, setCookie] = useCookies(['visitorId']);
    const [visitorId, setVisitorId] = useState(null);
    const [salesForceAccountInUserTable, setSalesforceAccountInUserTable] = useState(false);
    const dielineCard = useRef(null);
    const popup = useRef(null);
    const pouchPreview = useRef(null);

    const {
        register,
        formState: { errors, isSubmitting },
        handleSubmit,
        setValue,
        getValues,
        reset,
        control,
    } = useForm({
        // mode: "onBlur",
        shouldFocusError: true,
        defaultValues: {
            colorTargetInstructions: "",
            maxRollstockDiameter: "",
            maxRollstockImpressions: "",
            rollstockUnwindInstruction: "",
            rollstockTypeValue: "",
            rollstockTypeUomId: MeasureUnit.LengthInMillimeters,
        },
    });
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});


    useEffect(() => {
        isSubmitting ? loader.show() : loader.hide();
    }, [isSubmitting]);

    useEffect(() => {
        if (status !== "success" || loadOfferContextStatus !== "success") {
            console.log("useEffect ProductSpecs -- no offer", status, offer);
            // console.log("%c useEffect redirect value is", "color: #ff0000", redirect);
            return;
        }


        var id = cookies.visitorId;
        if (id) {
            setVisitorId(id);
        }

        //gaContext.sendOfferLoad(offer);
        const init = async () => {

            var offset = {};

            if (offerContext) {
                offset = {
                    zipper:
                        offerContext.zipperLocation && offerContext.zipperLocation.location
                            ? offerContext.zipperLocation.location.value
                            : null,
                    hangHole:
                        offerContext.hangHoleLocation && offerContext.hangHoleLocation.location
                            ? offerContext.hangHoleLocation.location.value
                            : null,
                    tearNotch:
                        offerContext.tearNotchLocation && offerContext.tearNotchLocation.location
                            ? offerContext.tearNotchLocation.location.value
                            : null,
                    valve:
                        offerContext.ventLocation && offerContext.ventLocation.location
                            ? offer.ventLocation.value
                            : null,
                };
                setFormData((prev) => ({
                    ...prev,
                    ...{
                        colorTargetInstructions: offerContext.colorTargetInstructions,
                        rollstockTypeSelection: offerContext.rollstockTypeSelection,
                        rollstockTypeValue: offerContext.rollstockTypeValue,
                        rollstockTypeUomId: offerContext.rollstockTypeUomId,
                        unwind: offerContext.unwind,
                    },
                }));

                console.log("init offset from offerContext", offerContext);

                let resetContext = {
                    colorTargetInstructions: offerContext.colorTargetInstructions,
                };

                if (offerContext.maxRollstockDiameter) {
                    resetContext.rollstockTypeSelection = "1";
                    resetContext.rollstockTypeValue = offerContext.maxRollstockDiameter.value;
                } else if (offerContext.maxRollstockImpressions) {
                    resetContext.rollstockTypeSelection = "2";
                    resetContext.rollstockTypeValue = offerContext.maxRollstockImpressions;
                }

                if (offerContext.rollstockUnwindInstruction) {
                    resetContext.rollstockUnwindInstruction = {
                        value: offerContext.rollstockUnwindInstruction.id,
                        label: offerContext.rollstockUnwindInstruction.value,
                    };
                }

                console.log("reset context, offer", resetContext, offer);

                reset(resetContext);

            } else {
                offset = {
                    zipper:
                        offer.packagingFeature.zipper && offer.packagingFeature.zipper.location
                            ? offer.packagingFeature.zipper.location.value
                            : null,
                    hangHole:
                        offer.packagingFeature.hangHole && offer.packagingFeature.hangHole.location
                            ? offer.packagingFeature.hangHole.location.value
                            : null,
                    tearNotch:
                        offer.packagingFeature.tearNotch && offer.packagingFeature.tearNotch.location
                            ? offer.packagingFeature.tearNotch.location.value
                            : null,
                    valve:
                        offer.packagingFeature.vent && offer.packagingFeature.vent.location
                            ? offer.packagingFeature.vent.location.value
                            : null,
                };
            }

            setOffset(offset);

            var features = {
                zipperId: offer.packagingFeature.zipper ? offer.packagingFeature.zipper.id : null,
                tearNotchId: offer.packagingFeature.tearNotch ? offer.packagingFeature.tearNotch.id : null,
                hangHoleId: offer.packagingFeature.hangHole ? offer.packagingFeature.hangHole.id : null,
                valveId: offer.packagingFeature.vent ? offer.packagingFeature.vent.id : null,
            };

            setFeaturesValue(features);

            setFeatureValue(offer.packagingFeature);
            setChangeOffsetDisabled(offer.salesChannel === SalesChannel.WebSite || !isOrderPlacementEnabled || isReorder);
            setKey(globalOffer.globalOfferId);
            setFormData({
                zipper: offset.zipper
                    ? {
                        value: offset.zipper ? offset.zipper : null,
                        uomId: offset.zipper ? offer.dimensions.measureUnitId : null,
                    }
                    : null,
                hangHole: offset.hangHole
                    ? {
                        value: offset.hangHole ? offset.hangHole : null,
                        uomId: offset.hangHole ? offer.dimensions.measureUnitId : null,
                    }
                    : null,
                tearNotch: offset.tearNotch
                    ? {
                        value: offset.tearNotch ? offset.tearNotch : null,
                        uomId: offset.tearNotch ? offer.dimensions.measureUnitId : null,
                    }
                    : null,
                valve: offset.valve
                    ? {
                        value: offset.valve ? offset.valve : null,
                        uomId: offset.valve ? offer.dimensions.measureUnitId : null,
                    }
                    : null,
            });

            if (process.env.REACT_APP_SHOW_REGISTRATION_POPUP){
                var account = await api.getAsJson(`api/user/search/account?sfid=${salesforceAccount.id}`);
                console.log("account for salesforce", account);
                if (account.hasSuccess){
                    setSalesforceAccountInUserTable(true);
                }
            }

            setLoaded(true);
        };
        if (status !== "success" || !offer) {
            // console.log("useEffect offer not initialized yet");
            return;
        }

        if (!loaded) {
            //console.log("not loaded, initializing");
            init();
        }
    }, [status, loadOfferContextStatus]);

    useEffect(() => {
        if (loaded && popup.current != null){
            popup.current.show();
        }
    }, [loaded])

    //var key = props.data.key;

    // var feature = offer.packagingFeature;
    // var formattedDimensions = props.data.formattedDimensions;
    // var salesChannel = offer.salesChannel === SalesChannel.WebSite ? SalesChannel.WebSite : SalesChannel.Estimator;

    //const siteContext = useContext(SiteContext);
    const [dielineControl, setDielineControl] = useState({});
    const [dielineHasErrors, setDielineHasErrors] = useState(false);
    const [dielineErrors, setDielineErrors] = useState([]);

    const [formData, setFormData] = useState(null);

    const setFormValues = (key, value) => {
        setFormData((prev) => ({
            ...prev,
            ...{
                [key]: value,
            },
        }));

        // if (props.updateForm) {
        //     props.updateForm(key, value);
        // }
    };

    const hasFeatureError = (feature) => {
        let err = dielineErrors.find((e) => e.feature1 == feature);
        return err ? true : false;
    };

    const getFeatureError = (feature) => {
        let err = dielineErrors.find((e) => e.feature1 == feature);
        return err ? err.errorMessage : null;
    };

    const applyFeatureLocation = (featureLocationType, value) => {

        dielineCard.current.expand();

        setFeature(featureLocationType, value);

        if (
            offer.packagingFeature.dielineInstructions &&
            offer.packagingFeature.dielineInstructions.featureDependencies
        ) {
            offer.packagingFeature.dielineInstructions.featureDependencies.forEach((dependency) => {
                var parent = getFeatureLocationType(dependency.parent);
                if (featureLocationType == parent) {
                    var child = getFeatureLocationType(dependency.child);
                    var val = dependency.offset ? parseFloat(value) - parseFloat(dependency.offset) : parseFloat(value);
                    setFeature(child, val);
                }
            });
        }

        var request = {
            ignoreErrorCodes: offer.packagingFeature.dielineInstructions
                ? offer.packagingFeature.dielineInstructions.ignoreErrorCodes
                : null,
            zipperId: features.zipperId,
            zipperOffset: offset.zipper,
            tearNotchId: features.tearNotchId,
            tearNotchOffset: offset.tearNotch,
            hangHoleId: features.hangHoleId,
            hangHoleOffset: offset.hangHole,
            valveId: features.valveId,
            valveOffset: offset.valve,
        };

        dielineControl.setLocationFeatures(request);
    };

    const setFeature = (featureLocationType, value) => {
        var val = parseFloat(value);

        switch (featureLocationType) {
            case FeatureLocationType.Zipper:
                offset.zipper = val;
                setFormValues("zipper", { value: val, uomId: offer.dimensions.measureUnitId });
                // gaContext.sendFeatureLocationChange(FeatureLocationType.Zipper, val, offer.dimensions.measureUnitId);
                break;
            case FeatureLocationType.TearNotch:
                offset.tearNotch = val;
                setFormValues("tearNotch", { value: val, uomId: offer.dimensions.measureUnitId });
                // gaContext.sendFeatureLocationChange(FeatureLocationType.TearNotch, val, offer.dimensions.measureUnitId);
                break;
            case FeatureLocationType.HangHole:
                offset.hangHole = val;
                setFormValues("hangHole", { value: val, uomId: offer.dimensions.measureUnitId });
                // gaContext.sendFeatureLocationChange(FeatureLocationType.HangHole, val, offer.dimensions.measureUnitId);
                break;
            case FeatureLocationType.Valve:
                offset.valve = val;
                setFormValues("valve", { value: val, uomId: offer.dimensions.measureUnitId });
                // gaContext.sendFeatureLocationChange(FeatureLocationType.Valve, val, offer.dimensions.measureUnitId);
                break;
            default:
                throw new Error(`${featureLocationType} is not supported`);
        }
    };

    const hasFeatureDependency = (featureLocationType) => {
        var result = false;
        if (
            offer &&
            offer.packagingFeature &&
            offer.packagingFeature.dielineInstructions &&
            offer.packagingFeature.dielineInstructions.featureDependencies
        ) {
            var code = getFeatureCode(featureLocationType);
            var idx = offer.packagingFeature.dielineInstructions.featureDependencies.findIndex((e) => e.child == code);
            if (idx > -1) {
                result = true;
            }
        }
        return result;
    };

    const getFeatureCode = (featureLocationType) => {
        switch (featureLocationType) {
            case FeatureLocationType.Zipper:
                return "ZP";
            case FeatureLocationType.TearNotch:
                return "TN";
            case FeatureLocationType.HangHole:
                return "HH";
            case FeatureLocationType.Valve:
                return "VL";
            default:
                throw new Error(`${featureLocationType} is not supported`);
        }
    };

    const getFeatureLocationType = (code) => {
        switch (code) {
            case "ZP":
                return FeatureLocationType.Zipper;
            case "TN":
                return FeatureLocationType.TearNotch;
            case "HH":
                return FeatureLocationType.HangHole;
            case "VL":
                return FeatureLocationType.Valve;
            default:
                throw new Error(`${code} is not supported`);
        }
    };

    const getFeatureValueFromFormData = (key) => {
        if (formData && formData[key]) {
            return {
                value: formData[key].value,
                uomId: formData[key].uomId,
            };
        }
        return null;
    };

    const onSubmit = async (data, errors) => {

        var update = {
            zipperLocation: getFeatureValueFromFormData("zipper"),
            hangHoleLocation: getFeatureValueFromFormData("hangHole"),
            tearNotchLocation: getFeatureValueFromFormData("tearNotch"),
            ventLocation: getFeatureValueFromFormData("valve"),
            colorTargetInstructions: data.colorTargetInstructions,
            // rollstockTypeSelection: formData["rollstockTypeSelection"],
            // rollstockTypeValue: formData["rollstockTypeValue"],
            // rollstockTypeUomId: formData["rollstockTypeUomId"],
            // unwind: formData["unwind"],
        };

        if (data.rollstockTypeSelection === "1") {
            update.maxRollstockDiameter = {
                value: data.rollstockTypeValue,
                uomId: offer.dimensions.measureUnitId
            };
            update.maxRollstockImpressions = null;
        } else if (data.rollstockTypeSelection === "2") {
            update.maxRollstockImpressions = data.rollstockTypeValue;
            update.maxRollstockDiameter = null;
        }

        if (data.rollstockUnwindInstruction) {
            update.rollstockUnwindInstruction = {
                id: data.rollstockUnwindInstruction.value,
                value: data.rollstockUnwindInstruction.label,
            };
        }

        //Important -- don't remove this
        update.salesForceAccountId = offer.salesForceAccountId;
        update.opportunityReferenceNumber = offer.salesForceOpportunity.referenceNumber;

        console.log("form data", data);
        console.log("update data", update);
        // console.log("Submitting", data, formData, update);

        await saveContext(update);

        navigate(`/offer/${globalOffer.globalOfferId}/skus`);
    };

    const trackHelp = (element) => {

        if (offer && offer.analyticsMetadata) {
            trackEvent({
                event: 'ordering_tool_interactions',
                eventProps: {
                    'step': 'ordering_tool_interactions',
                    'product_category': offer.analyticsMetadata.industryName,
                    'product_sub_category': offer.analyticsMetadata.industryCategoryName,
                    'selection': "information",
                    'element': element
                },
                userId: cookies.visitorId
            });
        }
    }

    const createGaContext = (element, event) => {
        if (!analyticsMetadata) {
            return null;
        }

        var context = {
            step: 'placement_1',
            industryName: analyticsMetadata.industryName,
            industryCategoryName: analyticsMetadata.industryCategoryName,
            userId: cookies.visitorId
        };

        if (element) {
            context.selection = element;
        }

        return context;
    }

    const onDielineInit = ctrl => {
        setDielineControl(ctrl);

        if (!isOrderPlacementEnabled) {
            dielineCard.current.expand();
            fitDieline(true);
        }

    }

    const fitDieline = (expanded) => {
        if (expanded) {
            setTimeout(() => {
                if (dielineControl) {
                    dielineControl.fit();
                }
            }, 250);
        }
    }

    return (
        <div>
            {loaded && offer &&
                <div>
                    <ShoppingBreadcrumbs active={5} ignore={offer.salesChannel == 'Estimator' ? 4 : 0} />

                    <OrderReference orderReference={orderReference} />
                    {process.env.REACT_APP_SHOW_REGISTRATION_POPUP === "true" && (salesForceAccountInUserTable === false || (salesForceAccountInUserTable === true && !account)) && (
                    <UnauthenticatedTemplate>
                        <RegisterSignInPopup
                            ref={popup}
                            onCloseClick={() => popup.current.close()}
                            salesforceAccountId={salesforceAccount.id}
                            redirectTo={window.location.href}
                            />
                    </UnauthenticatedTemplate>)}

                    <div className="productSpecs">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {threeDError
                                ?
                                <ExpandableCard ref={dielineCard} useHide={true} expanded={false} title={language.translateLabel("d2264bda-e338-4632-88f7-773dd59804d7", "Dieline")} onChange={(value) => { fitDieline(value); } }>
                                    <DielineImage
                                        offerKey={key}
                                        dielineContainsErrors={(hasErrors, err) => {
                                            setDielineHasErrors(hasErrors);
                                            let errors = err ? ErrorCodes.FromResponse(err) : [];
                                            setDielineErrors(errors);
                                        }}
                                        convertingMethodId={offer.convertingMethod.id}
                                        length={offer.dimensions.length}
                                        width={offer.dimensions.width}
                                        gusset={offer.dimensions.gusset}
                                        lengthUomId={offer.dimensions.measureUnitId}
                                        tearNotchId={offer.packagingFeature.tearNotch ? offer.packagingFeature.tearNotch.id : null}
                                        tearLineOffset={
                                            offer.packagingFeature.tearNotch && offer.packagingFeature.tearNotch.location
                                                ? offer.packagingFeature.tearNotch.location.value
                                                : null
                                        }
                                        hangholeId={offer.packagingFeature.hangHole ? offer.packagingFeature.hangHole.id : null}
                                        hangholeOffset={
                                            offer.packagingFeature.hangHole && offer.packagingFeature.hangHole.location
                                                ? offer.packagingFeature.hangHole.location.value
                                                : null
                                        }
                                        zipperId={offer.packagingFeature.zipper ? offer.packagingFeature.zipper.id : null}
                                        zipperOffset={
                                            offer.packagingFeature.zipper && offer.packagingFeature.zipper.location
                                                ? offer.packagingFeature.zipper.location.value
                                                : null
                                        }
                                        valveId={offer.packagingFeature.vent ? offer.packagingFeature.vent.id : null}
                                        valveOffset={
                                            offer.packagingFeature.vent && offer.packagingFeature.vent.location
                                                ? offer.packagingFeature.vent.location.value
                                                : null
                                        }
                                        sideSealWidthId={
                                            offer.packagingFeature.sealWidth ? offer.packagingFeature.sealWidth.id : null
                                        }
                                        roundedCornerId={
                                            offer.packagingFeature.roundedCorner ? offer.packagingFeature.roundedCorner.id : null
                                        }
                                        doubleCutId={offer.packagingFeature.doubleCut ? offer.packagingFeature.doubleCut.id : null}
                                        qrCodeTypeId={
                                            offer.packagingFeature.qrCodeType ? offer.packagingFeature.qrCodeType.id : null
                                        }
                                        gussetTypeId={
                                            offer.packagingFeature.gussetType ? offer.packagingFeature.gussetType.id : null
                                        }
                                        eyemarkId={offer.eyeMark ? offer.eyeMark.id : null}
                                        bottomFillId={offer.bottomFill ? offer.bottomFill.id : null}
                                        ignoreErrorCodes={
                                            offer.packagingFeature.dielineInstructions
                                                ? offer.packagingFeature.dielineInstructions.ignoreErrorCodes
                                                : null
                                        }
                                        onInit={(ctrl) => {
                                            onDielineInit(ctrl);
                                        }}
                                    />
                                </ExpandableCard>
                                :
                                <ExpandableCard ref={dielineCard} useHide={true} expanded={false} title={language.translateLabel("d2264bda-e338-4632-88f7-773dd59804d7", "Dieline")} onChange={(value) => { fitDieline(value); }}>
                                    <Carousel dragging={false} renderCenterLeftControls={({ previousSlide }) => (<div className='carousel-button left' onClick={previousSlide}>3D Model</div>)} renderCenterRightControls={({ nextSlide }) => (<div className='carousel-button right' onClick={() => { nextSlide(); fitDieline(true); }}>Dieline</div>)}>
                                        <ExistingOfferThreeDModel offerKey={key} width={window.innerWidth - (window.innerWidth * 0.2)} height={window.innerWidth - (window.innerWidth * 0.2)} onError={() => { setThreeDError(true) }} />
                                        <DielineImage
                                            offerKey={key}
                                            dielineContainsErrors={(hasErrors, err) => {
                                                setDielineHasErrors(hasErrors);
                                                let errors = err ? ErrorCodes.FromResponse(err) : [];
                                                setDielineErrors(errors);
                                            }}
                                            convertingMethodId={offer.convertingMethod.id}
                                            length={offer.dimensions.length}
                                            width={offer.dimensions.width}
                                            gusset={offer.dimensions.gusset}
                                            lengthUomId={offer.dimensions.measureUnitId}
                                            tearNotchId={offer.packagingFeature.tearNotch ? offer.packagingFeature.tearNotch.id : null}
                                            tearLineOffset={
                                                offer.packagingFeature.tearNotch && offer.packagingFeature.tearNotch.location
                                                    ? offer.packagingFeature.tearNotch.location.value
                                                    : null
                                            }
                                            hangholeId={offer.packagingFeature.hangHole ? offer.packagingFeature.hangHole.id : null}
                                            hangholeOffset={
                                                offer.packagingFeature.hangHole && offer.packagingFeature.hangHole.location
                                                    ? offer.packagingFeature.hangHole.location.value
                                                    : null
                                            }
                                            zipperId={offer.packagingFeature.zipper ? offer.packagingFeature.zipper.id : null}
                                            zipperOffset={
                                                offer.packagingFeature.zipper && offer.packagingFeature.zipper.location
                                                    ? offer.packagingFeature.zipper.location.value
                                                    : null
                                            }
                                            valveId={offer.packagingFeature.vent ? offer.packagingFeature.vent.id : null}
                                            valveOffset={
                                                offer.packagingFeature.vent && offer.packagingFeature.vent.location
                                                    ? offer.packagingFeature.vent.location.value
                                                    : null
                                            }
                                            sideSealWidthId={
                                                offer.packagingFeature.sealWidth ? offer.packagingFeature.sealWidth.id : null
                                            }
                                            roundedCornerId={
                                                offer.packagingFeature.roundedCorner ? offer.packagingFeature.roundedCorner.id : null
                                            }
                                            doubleCutId={offer.packagingFeature.doubleCut ? offer.packagingFeature.doubleCut.id : null}
                                            qrCodeTypeId={
                                                offer.packagingFeature.qrCodeType ? offer.packagingFeature.qrCodeType.id : null
                                            }
                                            gussetTypeId={
                                                offer.packagingFeature.gussetType ? offer.packagingFeature.gussetType.id : null
                                            }
                                            eyemarkId={offer.eyeMark ? offer.eyeMark.id : null}
                                            bottomFillId={offer.bottomFill ? offer.bottomFill.id : null}
                                            ignoreErrorCodes={
                                                offer.packagingFeature.dielineInstructions
                                                    ? offer.packagingFeature.dielineInstructions.ignoreErrorCodes
                                                    : null
                                            }
                                            onInit={(ctrl) => {
                                                onDielineInit(ctrl);
                                            }}
                                        />
                                    </Carousel>
                                </ExpandableCard>
                            }

                            {offer.convertingMethod.componentType.id !== ComponentType.Rollstock && (
                                <Card
                                    title={language.translateLabel("a6c19a28-3460-4ce4-b500-364507f7a7d8", "Features")}
                                    subtitle={language.translateLabel(
                                        "5f2c2e83-7e65-48e5-aa03-daaa9086cf47",
                                        "Please validate the selected features below and the measurements"
                                    )}
                                >
                                    {dielineHasErrors && (
                                        <Alert type="error">
                                            {language.translateLabel(
                                                "e9d7909a-655b-4ce6-8fa3-c1c6a6232f6d",
                                                "The Dieline is not valid and cannot be manufactured. Please see the notifications below and correct the errors by adjusting the measurements."
                                            )}
                                        </Alert>
                                    )}

                                    <Row>
                                        {feature.zipper && (
                                            <Column>
                                                <SectionTitle
                                                    offerKey={key}
                                                    cms="zipper"
                                                    orderPlacementApi={api}
                                                    image="af959a05-b263-4695-8878-68a348b8585d"
                                                    gaContext={createGaContext("Zipper")}
                                                    onShowHelp={() => {
                                                        // gaContext.sendHelpClick("Zipper");

                                                    }}
                                                >
                                                    {language.translateLabel("68e22377-f7f9-4796-ac80-23e42ae8bee1", "Zipper")}
                                                </SectionTitle>
                                                <SectionLabel description={feature.zipper.value}>
                                                    {feature.zipper.location &&
                                                        !hasFeatureDependency(FeatureLocationType.Zipper) && (
                                                            <OffsetEditor
                                                                disabled={changeOffsetDisabled}
                                                                title={language.translateLabel(
                                                                    "68e22377-f7f9-4796-ac80-23e42ae8bee1",
                                                                    "Zipper"
                                                                )}
                                                                offset={feature.zipper.location}
                                                                errorMessage={getFeatureError(ErrorCode.Feature.Zipper)}
                                                                hasError={hasFeatureError(ErrorCode.Feature.Zipper)}
                                                                onChanged={(value, uom) => {
                                                                    applyFeatureLocation(FeatureLocationType.Zipper, value);
                                                                }}
                                                            />
                                                            // <HookOffsetEditor
                                                            //     getValues={getValues}
                                                            //     setValue={setValue}
                                                            //     disabled={salesChannel === SalesChannel.WebSite}
                                                            //     featureType="zipperLocation"
                                                            //     register={register}
                                                            //     get={get}
                                                            //     isValid={isValid}
                                                            //     errors={errors}
                                                            //     resetField={resetField}
                                                            //     title={language.translateLabel(
                                                            //         "68e22377-f7f9-4796-ac80-23e42ae8bee1",
                                                            //         "Zipper"
                                                            //     )}
                                                            //     offset={feature.zipper.location}
                                                            //     errorMessage={getFeatureError(ErrorCode.Feature.Zipper)}
                                                            //     hasError={hasFeatureError(ErrorCode.Feature.Zipper)}
                                                            //     onChanged={(value, uom) => {
                                                            //         console.log("zipper change", value, uom);
                                                            //         applyFeatureLocation(FeatureLocationType.Zipper, value);
                                                            //     }}
                                                            // />
                                                        )}
                                                </SectionLabel>
                                            </Column>
                                        )}

                                        {feature.tearNotch && (
                                            <Column>
                                                <SectionTitle
                                                    offerKey={key}
                                                    cms="tear-notch"
                                                    orderPlacementApi={api}
                                                    image="e41d1414-c1bd-4302-a410-b40a041d3c76"
                                                    gaContext={createGaContext("Tear Notch")}
                                                    onShowHelp={() => {
                                                        // gaContext.sendHelpClick("Tear Notch");
                                                    }}
                                                >
                                                    {language.translateLabel(
                                                        "ab0dd63d-79c9-4b5f-bff1-8c6accf6b023",
                                                        "Tear Notch"
                                                    )}
                                                </SectionTitle>
                                                <SectionLabel description={feature.tearNotch.value}>
                                                    {feature.tearNotch.location &&
                                                        !hasFeatureDependency(FeatureLocationType.TearNotch) && (
                                                            <OffsetEditor
                                                                disabled={changeOffsetDisabled}
                                                                title={language.translateLabel(
                                                                    "ab0dd63d-79c9-4b5f-bff1-8c6accf6b023",
                                                                    "Tear Notch"
                                                                )}
                                                                offset={feature.tearNotch.location}
                                                                errorMessage={getFeatureError(ErrorCode.Feature.TearNotch)}
                                                                hasError={hasFeatureError(ErrorCode.Feature.TearNotch)}
                                                                onChanged={(value, uom) => {
                                                                    applyFeatureLocation(FeatureLocationType.TearNotch, value);
                                                                }}
                                                            />
                                                        )}
                                                </SectionLabel>
                                            </Column>
                                        )}
                                    </Row>
                                    <Row>
                                        {feature.hangHole && (
                                            <Column>
                                                <SectionTitle
                                                    offerKey={key}
                                                    cms="hang-hole"
                                                    orderPlacementApi={api}
                                                    image="13306d0c-0ca7-4813-9397-9aca00719ee0"
                                                    gaContext={createGaContext("Hang Hole")}
                                                    onShowHelp={() => {
                                                        // gaContext.sendHelpClick("Hang Hole");
                                                        // trackHelp("Hang Hole");
                                                    }}
                                                >
                                                    {language.translateLabel(
                                                        "81702163-18c0-4de5-a792-6e6c98abdea8",
                                                        "Hang Hole"
                                                    )}
                                                </SectionTitle>
                                                <SectionLabel description={feature.hangHole.value}>
                                                    {feature.hangHole.location &&
                                                        !hasFeatureDependency(FeatureLocationType.HangHole) && (
                                                            <OffsetEditor
                                                                disabled={changeOffsetDisabled}
                                                                title={language.translateLabel(
                                                                    "81702163-18c0-4de5-a792-6e6c98abdea8",
                                                                    "Hang Hole"
                                                                )}
                                                                offset={feature.hangHole.location}
                                                                errorMessage={getFeatureError(ErrorCode.Feature.HangHole)}
                                                                hasError={hasFeatureError(ErrorCode.Feature.HangHole)}
                                                                onChanged={(value, uom) => {
                                                                    applyFeatureLocation(FeatureLocationType.HangHole, value);
                                                                }}
                                                            />
                                                        )}
                                                </SectionLabel>
                                            </Column>
                                        )}
                                        {feature.vent && (
                                            <Column>
                                                <SectionTitle
                                                    offerKey={key}
                                                    cms="valve"
                                                    orderPlacementApi={api}
                                                    image="9da0c23f-6709-4d55-bb64-f1f09ee63284"
                                                    gaContext={createGaContext("Valve")}
                                                    onShowHelp={() => {
                                                        // gaContext.sendHelpClick("Valve");
                                                    }}
                                                >
                                                    {language.translateLabel("47e24a00-6d3e-4141-b3c0-d95d425dee81", "Valve")}
                                                </SectionTitle>
                                                <SectionLabel description={feature.vent.value}>
                                                    {feature.vent.location &&
                                                        !hasFeatureDependency(FeatureLocationType.Valve) && (
                                                            <OffsetEditor
                                                                disabled={changeOffsetDisabled}
                                                                title={language.translateLabel(
                                                                    "47e24a00-6d3e-4141-b3c0-d95d425dee81",
                                                                    "Valve"
                                                                )}
                                                                offset={feature.vent.location}
                                                                errorMessage={getFeatureError(ErrorCode.Feature.Valve)}
                                                                hasError={hasFeatureError(ErrorCode.Feature.Valve)}
                                                                onChanged={(value, uom) => {
                                                                    applyFeatureLocation(FeatureLocationType.Valve, value);
                                                                }}
                                                            />
                                                        )}
                                                </SectionLabel>
                                            </Column>
                                        )}
                                    </Row>
                                </Card>
                            )}

                            <Card
                                title={language.translateLabel("357a8a69-4bb5-4068-9541-89cb26b6d458", "Specifications")}
                                subtitle={language.translateLabel(
                                    "d32a45ca-4148-4aad-8657-b559548f73c1",
                                    "If any of the below specifications need to be changed, please contact your sales representative"
                                )}
                            >
                                <Row>
                                    <Column>
                                        <SectionTitle
                                            offerKey={key}
                                            cms="dimensions"
                                            orderPlacementApi={api}
                                            gaContext={createGaContext("Dimensions")}
                                            onShowHelp={() => {
                                                // gaContext.sendHelpClick("Dimensions");
                                            }}
                                        >
                                            {language.translateLabel("5ab1fc30-0933-4425-bad4-b7e94887935d", "Dimensions")}
                                        </SectionTitle>
                                        <div className='sectionLabel'>
                                            <div className="product-description">{formattedSizeString}</div>
                                        </div>
                                    </Column>

                                    <Column>
                                        <SectionTitle
                                            offerKey={key}
                                            cms="material"
                                            orderPlacementApi={api}
                                            gaContext={createGaContext("Material")}
                                            onShowHelp={() => {
                                                // gaContext.sendHelpClick("Material");
                                            }}
                                        >
                                            {language.translateLabel("237f31ad-e94d-4024-92b0-83b915ec9c00", "Material")}
                                        </SectionTitle>
                                        <SectionLabel description={"SPEC-" + offer.packagingMaterial.structureSpecId}>
                                            <DownloadLink
                                                href={`${offer.packagingMaterial.pdfUrl}?culture=${offer.supportedCountry.cultureString}`}
                                                onClick={() => {
                                                    // gaContext.sendHelpClick("Information Sheet");
                                                    trackHelp("Information Sheet");
                                                }}
                                            >
                                                {language.translateLabel(
                                                    "0717f632-37ca-4e42-832c-689ee4841fdb",
                                                    "Information Sheet"
                                                )}
                                            </DownloadLink>
                                        </SectionLabel>
                                    </Column>
                                </Row>

                                <Row>
                                    <Column>
                                        <SectionTitle
                                            offerKey={key}
                                            cms="sku-count"
                                            orderPlacementApi={api}
                                            gaContext={createGaContext("SKU Count")}
                                            onShowHelp={() => {
                                                // gaContext.sendHelpClick("SKU Count");
                                            }}
                                        >
                                            {language.translateLabel("b8b51cda-bc7c-4798-9b17-4534caa25aaa", "SKU Count")}
                                        </SectionTitle>
                                        <SectionLabel description={offer.skuCount} />
                                    </Column>
                                    <Column>
                                        <SectionTitle
                                            offerKey={key}
                                            cms="eyemark-placement"
                                            orderPlacementApi={api}
                                            gaContext={createGaContext("Eyemark Placement")}
                                            onShowHelp={() => {
                                                // gaContext.sendHelpClick("Eyemark Placement");
                                            }}
                                        >
                                            {language.translateLabel("af463558-b3b7-4d24-a547-3764d1d7431c", "Eyemarks")}
                                        </SectionTitle>
                                        <SectionLabel description={offer.eyeMark ? offer.eyeMark.value : "--"} />
                                    </Column>
                                </Row>

                                {offer.convertingMethod.componentType.id !== ComponentType.Rollstock && (
                                    <Row>
                                        <Column>
                                            <SectionTitle
                                                offerKey={key}
                                                cms="color-specification"
                                                orderPlacementApi={api}
                                                gaContext={createGaContext("Color Specification")}
                                                onShowHelp={() => {
                                                    // gaContext.sendHelpClick("Color Specification");
                                                    trackHelp("Color Specification");
                                                }}
                                            >
                                                {language.translateLabel(
                                                    "a621f4d8-8783-4852-acb9-29007f7a3d0f",
                                                    "Color Specification"
                                                )}
                                            </SectionTitle>
                                            <SectionLabel description={offer.colorSpec.value} />
                                        </Column>
                                        <Column>
                                            <SectionTitle
                                                offerKey={key}
                                                cms="rounded-corners"
                                                orderPlacementApi={api}
                                                image="2c791cd2-b235-4f29-81c8-8ae84900aa75"
                                                gaContext={createGaContext("Rounded Corner")}
                                                onShowHelp={() => {
                                                    // gaContext.sendHelpClick("Rounded Corner");
                                                }}
                                            >
                                                {language.translateLabel(
                                                    "7c696bb1-2fc7-477a-b9fe-ec25c674d91d",
                                                    "Rounded Corners"
                                                )}
                                            </SectionTitle>
                                            <SectionLabel
                                                description={feature.roundedCorner ? feature.roundedCorner.value : "--"}
                                            />
                                        </Column>
                                    </Row>
                                )}

                                <Row>
                                    <Column>
                                        <SectionTitle
                                            offerKey={key}
                                            cms="seal-width"
                                            orderPlacementApi={api}
                                            image="debd99a5-6ad0-49fc-85c5-0b0e0af723ce"
                                            gaContext={createGaContext("Seal Width")}
                                            onShowHelp={() => {
                                                // gaContext.sendHelpClick("Seal Width");
                                            }}
                                        >
                                            {language.translateLabel("fbe5f534-ba05-4a65-b3cc-385b63e59254", "Seal Width")}
                                        </SectionTitle>
                                        <SectionLabel description={feature.sealWidth ? feature.sealWidth.value : "--"} />
                                    </Column>

                                    <Column>
                                        <SectionTitle
                                            offerKey={key}
                                            cms="epac-connect"
                                            orderPlacementApi={api}
                                            gaContext={createGaContext("ePac Connect")}
                                            onShowHelp={() => {
                                                // gaContext.sendHelpClick("ePac Connect");
                                            }}
                                        >
                                            {language.translateLabel("9d37c5ce-4a1e-4997-9bcb-2541ab4679bc", "ePac Connect")}
                                        </SectionTitle>
                                        <SectionLabel description={feature.qrCodeType ? feature.qrCodeType.value : "--"} />
                                    </Column>

                                </Row>
                                <Row>
                                    <Column>
                                        <SectionTitle
                                            offerKey={key}
                                            cms="double-cut"
                                            orderPlacementApi={api}
                                            gaContext={createGaContext("Double Cut")}
                                            onShowHelp={() => {
                                                // gaContext.sendHelpClick("Seal Width");
                                            }}
                                        >
                                            {language.translateLabel("911343ac-bc2e-44bd-91ae-3152847d469a", "Double Cut")}
                                        </SectionTitle>
                                        <SectionLabel description={feature.doubleCut ? feature.doubleCut.value : "--"} />
                                    </Column>

                                    <Column>
                                        <SectionTitle
                                            offerKey={key}
                                            cms="gusset-type"
                                            orderPlacementApi={api}
                                            gaContext={createGaContext("Gusset Type")}
                                            onShowHelp={() => {
                                                // gaContext.sendHelpClick("ePac Connect");
                                            }}
                                        >
                                            {language.translateLabel("e3af51f1-9f65-4b02-aac3-a8e3ff5cf7d0", "Gusset Type")}
                                        </SectionTitle>
                                        <SectionLabel description={feature.gussetType ? feature.gussetType.value : "--"} />
                                    </Column>
                                </Row>
                                <Row>
                                    <Column>
                                        <SectionTitle
                                            offerKey={key}
                                            cms="bottom-fill"
                                            orderPlacementApi={api}
                                            gaContext={createGaContext("Bottom Fill")}
                                            onShowHelp={() => {
                                            }}
                                        >
                                            {language.translateLabel("1b929272-0f00-4c8c-b3ae-b1424beae707", "Bottom Fill")}
                                        </SectionTitle>
                                        <SectionLabel description={offer.bottomFill ? offer.bottomFill.value : "--"} />
                                    </Column>
                                    <Column>
                                        <SectionTitle
                                            offerKey={key}
                                            cms="core"
                                            orderPlacementApi={api}
                                            gaContext={createGaContext("Core")}
                                            onShowHelp={() => {
                                                // gaContext.sendHelpClick("Core");
                                            }}
                                        >
                                            {language.translateLabel("349e0a08-0587-4e0b-beb4-a9c7afd15820", "Core")}
                                        </SectionTitle>
                                        <SectionLabel description={offer.core ? offer.core.value : "None"} />
                                    </Column>
                                </Row>
                                <Row>
                                    <Column>
                                        <SectionTitle
                                            offerKey={key}
                                            cms='additional-features'
                                            orderPlacementApi={api}
                                            gaContext={createGaContext("Additional Features")}
                                            onShowHelp={() => {
                                                // gaContext.sendHelpClick("Additional Features");
                                            }}
                                            category='Label'
                                        >
                                            {language.translateLabel("b91d4109-3f7f-456f-8151-60d5d1637322", "Additional Features")}
                                        </SectionTitle>
                                        <SectionLabel description={offer.packagingFeature.outsourcedFeatures ? offer.packagingFeature.outsourcedFeatures.map(x => x.value).join(', ') : "None"} />
                                    </Column>
                                    <Column>
                                    </Column>
                                </Row>
                            </Card>

                            {isOrderPlacementEnabled &&
                                <Card
                                    title={language.translateLabel(
                                        "07f29958-5d6f-41e9-9f9f-b7ef4bc8417d",
                                        "Additional Manufacturing Instructions"
                                    )}
                                >
                                    <SectionTitle
                                        offerKey={key}
                                        cms="color-target-instructions"
                                        orderPlacementApi={api}
                                        gaContext={createGaContext("Color Instructions")}
                                        onShowHelp={() => {
                                            //gaContext.sendHelpClick("Color Instructions");
                                        }}
                                    >
                                        {language.translateLabel("6449df23-7d25-4fd8-952f-94a74963ee75", "Color Instructions")}
                                    </SectionTitle>
                                    <div>
                                        <input
                                            type="text"
                                            maxLength={50}
                                            className="control textbox"
                                            {...register("colorTargetInstructions", {
                                                maxLength: {
                                                    value: 50,
                                                    message: "Color target instructions cannot exceed 50 characters."
                                                }
                                            })}
                                        />
                                    </div>

                                    {offer.convertingMethod.componentType.id === ComponentType.Rollstock && (
                                        <div>
                                            <SectionTitle offerKey={key} cms="roll-restrictions" orderPlacementApi={api}
                                                required
                                                gaContext={createGaContext("Roll Restrictions")}>
                                                {language.translateLabel(
                                                    "9bc052df-99c1-462f-add7-e581d17bcfe6",
                                                    "Roll Restrictions"
                                                )}
                                            </SectionTitle>
                                            <RollRestrictionEditor
                                                offerKey={key}
                                                offer={offer}
                                                orderPlacementApi={api}
                                                register={register}
                                                errors={errors}
                                                getValues={getValues}
                                                onChange={(type, value) => {
                                                    setFormValues("rollstockTypeSelection", type);
                                                    setFormValues("rollstockTypeValue", value);
                                                    setFormValues("rollstockTypeUomId", MeasureUnit.LengthInMillimeters);
                                                }}
                                            />

                                            <SectionTitle offerKey={key} cms="unwind-direction" orderPlacementApi={api}
                                                required
                                                gaContext={createGaContext("Unwind Direction")}>
                                                {language.translateLabel(
                                                    "5a0db080-4516-4225-b33a-add7fcd3bb4c",
                                                    "Unwind Direction"
                                                )}
                                            </SectionTitle>
                                            <UnwindDirectionDropdown
                                                required
                                                offerKey={key}
                                                orderPlacementApi={api}
                                                errors={errors}
                                                control={control}
                                            />
                                        </div>
                                    )}
                                </Card>
                            }

                            {offer.salesChannel !== 'Estimator' &&
                                <button
                                    className="btn btn-tertiary-outline back"
                                    onClick={() => {
                                        trackEvent({
                                            event: 'ordering_tool_interactions',
                                            eventProps: {
                                                'step': 'placement_1',        // order_shopping_5
                                                'product_category': analyticsMetadata.industryName,        // e.g. Food & Beverage
                                                'product_sub_category': analyticsMetadata.industryCategoryName, // E.g. Dry Mix
                                                'element': 'back'
                                            },
                                            userId: cookies.visitorId
                                        });
                                        window.location = !isReorder ? `/?reviveCart=true` : `myreorders`;
                                    }}
                                ><span className='btn-text'><Translation id='03473ff0-be47-4bd3-9ed8-190fedf344c5' defaultMessage='Back' category='Button' /></span>
                                </button>
                            }

                            {isOrderPlacementEnabled &&
                                <div className="pull-right">
                                    <button
                                        data-testid="order-placement-product-next-button"
                                        className="btn btn-primary"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            trackEvent({
                                                event: 'ordering_tool_interactions',
                                                eventProps: {
                                                    'step': 'placement_1',        // order_shopping_5
                                                    'product_category': analyticsMetadata.industryName,        // e.g. Food & Beverage
                                                    'product_sub_category': analyticsMetadata.industryCategoryName, // E.g. Dry Mix
                                                    'element': 'next'
                                                },
                                                userId: cookies.visitorId
                                            });

                                            trackEvent({ ecommerce: null });  // Clear the previous ecommerce object.
                                            trackEvent({
                                                event: 'begin_checkout',
                                                ecommerce: {
                                                    items: [
                                                        {
                                                            item_id: offer.product.id,
                                                            item_name: offer.product.value,
                                                            currency: offer.plant.currency,
                                                            //  index: quoteCount,            //the nth quote added, starting at 1
                                                            unit_price: offer.calculationBreakdown[0].price.unitPrice.amount,
                                                            item_category: offer.productGroup.value,  //what packaging category
                                                            item_category2: analyticsMetadata.industryCategoryName,      //what packaging sub-category
                                                            item_category3: offer.convertingMethod.componentType.value,     //pouch type
                                                            item_category4: offer.dimensions.width + " x " + offer.dimensions.length,      // width x length
                                                            price: offer.calculationBreakdown[0].price.totalPrice.amount,
                                                            quantity: offer.calculationBreakdown[0].quantityBreak,
                                                            skuCount: offer.skuCount
                                                        }
                                                    ]
                                                },
                                                userId: cookies.visitorId
                                            });
                                            handleSubmit(async (data, errors) => onSubmit(data, errors));
                                        }}
                                    >
                                        Next
                                    </button>
                                </div>
                            }

                        </form>
                    </div>
                </div>
            }
        </div>
    );
};

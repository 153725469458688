import React, { useState, useEffect, useContext } from "react";
import { DielineImage, ErrorCodes } from "../../components/offer/DielineImage";
import { Money } from "../../components/offer/Money";
import { Card } from "../../core/Card";
import { Column } from "../../core/Column";
import { ColumnTitle } from "../../core/ColumnTitle";
import { DownloadLink } from "../../core/DownloadLink";
import { FormatProvider } from "../../core/offer/FormatProvider";
import { Row } from "../../core/Row";
import { SectionLabel } from "../../core/SectionLabel";
import { SectionTitle } from "../../core/SectionTitle";
import { Translation } from "../../core/Translation";
import { Language } from "../../infrastructure/Languages";
import { Layout } from "./Layout";
import './OrderSummary.css';
import { ComponentType, SalesState } from "../../infrastructure/Constants";
import { useGlobalOfferContext } from "../../infrastructure/GlobalOfferId";
import OrderPlacementApi from "../../infrastructure/OrderPlacementApi";

export const OrderSummary = ({
    backwardsCompatibilityOffer,
    status,
    globalOfferId,
    customerSubmitDate
}) => {

    const [dielineControl, setDielineControl] = useState({});
    const [dielineHasErrors, setDielineHasErrors] = useState(false);
    const [dielineErrors, setDielineErrors] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const context = useContext(Language);
    const { offerContext } = useGlobalOfferContext(globalOfferId);

    const [offer, setOffer] = useState({});
    const [offerDataForLayout, setOfferDataForLayout] = useState({});
    const [feature, setFeature] = useState({});
    const [artworkSkus, setArtworkSkus] = useState([]);
    const [offerCurrency, setOfferCurrency] = useState({});
    const [unknownOffer, setUnknownOffer] = useState(false);
    const [orderIntent, setOrderIntent] = useState(null);
    const orderPlacementApi = new OrderPlacementApi("/");

    const getBreakdown = () => {
        var t = getTotals();
        console.log("getTotals() ", t);

        return offer.calculationBreakdown[0] ? offer.calculationBreakdown[0].initiallyDue : null;
    };

    const getTotals = () => {
        let totals = {
            subTotal: getSubTotal(),
            shipping: getShipping(),
            overage: getOverage(),
            tax: getTax(),
            connect: getConnect(),
            partialPayment: getPartialPayment(),
            estimatedShipping: getEstimatedShipping(),
            estimatedOverage: getEstimatedOverage(),
            totalAmountDue: orderIntent && orderIntent.orderIntentTotals ? orderIntent.orderIntentTotals.grandTotalAmount : getSubTotal() + getShipping() + getOverage(),
            creditAppliedAmount: orderIntent?.orderIntentTotals?.creditAppliedAmount < 0 ? orderIntent?.orderIntentTotals?.creditAppliedAmount : 0,
            refundDueAmount: orderIntent?.orderIntentTotals?.refundDueAmount < 0 ? orderIntent?.orderIntentTotals?.refundDueAmount : 0,
            totalAvailableCreditAmount: orderIntent?.orderIntentTotals?.totalAvailableCreditAmount < 0 ? orderIntent?.orderIntentTotals?.totalAvailableCreditAmount : 0
        };
        return totals;
    }

    const getTax = () => 0;
    const getConnect = () => {
        if (orderIntent && orderIntent.orderIntentTotals) {
            return orderIntent.orderIntentTotals.epacConnectAmount;
        }
    };
    const getPartialPayment = () => {
        if (orderIntent && orderIntent.orderIntentTotals) {
            return orderIntent.orderIntentTotals.partialPaymentAmount;
        }
    };
    const getEstimatedOverage = () => {
        if (orderIntent && orderIntent.orderIntentTotals) {
            return orderIntent.orderIntentTotals.estimatedOverageAmount;
        }
    };
    const getEstimatedShipping = () => {
        if (orderIntent && orderIntent.orderIntentTotals) {
            return orderIntent.orderIntentTotals.estimatedShippingAmount;
        }
    };

    const getOverage = () => {
        let epacConnectOverageTotal = 0;
        let productOverageTotal = 0;

        if (offer.isPressProof) {
            return 0;
        }

        if (orderIntent && orderIntent.orderIntentTotals) {
            return orderIntent.orderIntentTotals.overageAmount;
        }

        offer.calculationBreakdown.forEach(x => {
            if (x.sellingPrice && x.sellingPrice.epacConnect) {
                epacConnectOverageTotal += x.sellingPrice.epacConnect.totalPriceIncludingOverage.amount - x.sellingPrice.epacConnect.totalPrice.amount;
            }
            if (x.sellingPrice && x.sellingPrice.product) {
                productOverageTotal += x.sellingPrice.product.totalPriceIncludingOverage.amount - x.sellingPrice.product.totalPrice.amount;
            }
        });

        return epacConnectOverageTotal + productOverageTotal;
    }

    const getSubTotal = () => {
        if (offer.checkoutDetails.skuLineItems) {
            var total = offer.checkoutDetails.skuLineItems.reduce((x, y) => x + (y.unitPrice.amount * y.quantity), 0);
            return total;
        }

        return orderIntent.skuLineItems.reduce((x, y) => x + (y.unitPrice * y.quantity), 0);
    };

    const getShipping = () => {
        if (offer.checkoutDetails.skuLineItems) {
            var total = offer.calculationBreakdown.reduce((x, y) => x + y.sellingPrice.shipping.totalPrice.amount, 0);
            return total;
        }
        return orderIntent.orderIntentTotals.shippingAmount;
    };

    const isOrderInSummaryState = (stateId, orderIntent) => {
        return [SalesState.OrderCreated, SalesState.OrderSubmitted, SalesState.OfferReadyForProduction].find(x => x === stateId)
            || (orderIntent && (orderIntent.state === "PROCR" || orderIntent.state === "SBMIT"));
    }

    const formatQuantity = (value) => {
        return FormatProvider.FormatNumberWithCommas(value.toFixed(2));
    }

    const getCustomerName = () => {
        if (offer && offer.checkoutDetails && offer.checkoutDetails.billingAddress) {
            return offer.checkoutDetails.billingAddress.name;
        }

        if (orderIntent && orderIntent.shippingAddress) {
            return orderIntent.shippingAddress.name;
        }

        return 'N/A';
    }

    const getReorderArtwork = (reorderDetailsSkus) => {
        return offerContext.skuLineItems.map((x) => {
            return {
                skuDescription: x.description,
                location: reorderDetailsSkus.find(sku => sku.approvedSkuId === x.approvedSkuId).thumbnail
            }
        });
    }

    useEffect(() => {
        if (loaded) {
            return;
        }
        const load = async () => {
            console.log(backwardsCompatibilityOffer);

            if (backwardsCompatibilityOffer && status === 'success') {
                console.log("not loaded", backwardsCompatibilityOffer);
                if (backwardsCompatibilityOffer.offer) {
                    var offer = backwardsCompatibilityOffer.offer;

                    if (!(isOrderInSummaryState(offer.currentStateId, backwardsCompatibilityOffer.orderIntent))) {
                        setUnknownOffer(true);
                    }
                    else {
                        console.log('OrderSummaryLayout - Offer', offer);
                        console.log('OrderSummaryLayout - Contact', backwardsCompatibilityOffer.salesContact);
                        console.log('OrderSummaryLayout - Artwork', backwardsCompatibilityOffer.artwork);
                        console.log("OrderSummaryLayout - Order Intent", backwardsCompatibilityOffer.orderIntent);

                        offer.formattedDimensions = backwardsCompatibilityOffer.formattedSizeString;

                        if (offer?.offer?.supportedCountry?.cultureString) {
                            //TODO: What to do with this?
                            // await context.setLanguage(offer.offer.supportedCountry.cultureString);
                        }

                        setOffer(backwardsCompatibilityOffer.offer.offer);
                        setOfferDataForLayout(backwardsCompatibilityOffer);
                        setFeature(backwardsCompatibilityOffer.offer.offer.packagingFeature);
                        setOrderIntent(backwardsCompatibilityOffer.orderIntent.intentRequest);

                        var isReorder = offerContext.reorderDetailsCheckout != null;
                        if (isReorder) {
                            var artworks = getReorderArtwork(backwardsCompatibilityOffer.offer.offer.reorderDetails.skus); 
                            setArtworkSkus(artworks);
                        }
                        else {
                            var artwork = await orderPlacementApi.getArtworkSkus(globalOfferId);
                            if (artwork.hasSuccess)
                            {
                                setArtworkSkus(artwork.response);
                            }
                        }

                        //NOTE: If created with the Order API, the checkout details might not be there yet
                        var skuLineItems = backwardsCompatibilityOffer.offer.offer.checkoutDetails?.skuLineItems;

                        if (skuLineItems && skuLineItems[0]?.unitPrice?.currency) {
                            setOfferCurrency(backwardsCompatibilityOffer.offer.offer.checkoutDetails.skuLineItems[0].unitPrice.currency);
                        }
                        else {
                            setOfferCurrency(backwardsCompatibilityOffer.offer.offer.supportedCountry.currency);
                        }
                    }
                    setLoaded(true);
                }
                else {
                    // setUnknownOffer(true);
                    // setLoaded(true);
                }
            }
        }
        if (!loaded && offerContext) {
            load();
        }
    }, [backwardsCompatibilityOffer, status, globalOfferId, offerContext]);

    const render = () => {
        console.log(offerDataForLayout)
        console.log(offer)
        if (loaded && !unknownOffer) {
            return (
                <div className='orderSummaryLayout'>
                    <Layout offerData={offerDataForLayout} title={<Translation id='659d60c9-2df3-497d-b82d-5359522e2855' defaultMessage='ePac Sales Contact' category='Label' />}>
                        <Card title="Order">
                            <Row>
                                <Column width="2">
                                    <SectionTitle><Translation id='f523481b-db29-400f-9365-2347b0605bdd' defaultMessage='Customer Name' category='Label' /></SectionTitle>
                                </Column>
                                <Column width="2">
                                    <SectionTitle><Translation id='6c4a1fd6-0e1b-40b1-909e-d7ab09cfed98' defaultMessage='Order Date' category='Label' /></SectionTitle>
                                </Column>
                            </Row>
                            <Row>
                                <Column width="2">
                                    {getCustomerName()}
                                </Column>
                                <Column width="2">
                                    {new Date(offerDataForLayout.customerSubmitDate).toLocaleDateString()}
                                </Column>
                            </Row>
                            <Row>
                                <Column width="2">
                                    <SectionTitle><Translation id='b0c32d39-e842-4db1-9dd4-8e57f4467696' defaultMessage='Product' category='Label' /></SectionTitle>
                                </Column>
                                <Column width="2">
                                    <SectionTitle><Translation id='2e1ab490-6672-43c4-96cd-0e4cb0bbdb4e' defaultMessage='Payment Method' category='Label' /></SectionTitle>
                                </Column>
                            </Row>
                            <Row>
                                <Column width="2">
                                    {offer.product.value}
                                </Column>
                                <Column width="2">
                                    {offerDataForLayout.orderIntent && offerDataForLayout.orderIntent.intentRequest && offerDataForLayout.orderIntent.intentRequest.payment && offerDataForLayout.orderIntent.intentRequest.payment.paymentMethod ? offerDataForLayout.orderIntent.intentRequest.payment.paymentMethod : "--"}
                                </Column>
                            </Row>
                            {offer.checkoutDetails.poNumber &&
                                <>
                                    <Row>
                                        <Column width="2">
                                            <SectionTitle><Translation id='066aca46-ccb5-4a5e-a2c8-0b07b4ccf3c7' defaultMessage='Purchase Order Number' category='Label' /></SectionTitle>
                                        </Column>
                                        <Column width="2">&nbsp;</Column>
                                    </Row>
                                    <Row>
                                        <Column width="2">
                                            {offer.checkoutDetails.poNumber}
                                        </Column>
                                        <Column width="2">&nbsp;</Column>
                                    </Row>
                                </>
                            }
                        </Card>
                        <Card title={<Translation id='d2264bda-e338-4632-88f7-773dd59804d7' defaultMessage='Dieline' category='Card Title' />}>

                            <DielineImage
                                offerKey={globalOfferId}
                                dielineContainsErrors={(hasErrors, err) => {
                                    setDielineHasErrors(hasErrors);
                                    let errors = err ? ErrorCodes.FromResponse(err) : [];
                                    setDielineErrors(errors);
                                }}
                                convertingMethodId={offer.convertingMethod.id}
                                length={offer.dimensions.length}
                                width={offer.dimensions.width}
                                gusset={offer.dimensions.gusset}
                                lengthUomId={offer.dimensions.measureUnitId}
                                tearNotchId={offer.packagingFeature.tearNotch ? offer.packagingFeature.tearNotch.id : null}
                                tearLineOffset={offer.packagingFeature.tearNotch && offer.packagingFeature.tearNotch.location ? offer.packagingFeature.tearNotch.location.value : null}
                                hangholeId={offer.packagingFeature.hangHole ? offer.packagingFeature.hangHole.id : null}
                                hangholeOffset={offer.packagingFeature.hangHole && offer.packagingFeature.hangHole.location ? offer.packagingFeature.hangHole.location.value : null}
                                zipperId={offer.packagingFeature.zipper ? offer.packagingFeature.zipper.id : null}
                                zipperOffset={offer.packagingFeature.zipper && offer.packagingFeature.zipper.location ? offer.packagingFeature.zipper.location.value : null}
                                valveId={offer.packagingFeature.vent ? offer.packagingFeature.vent.id : null}
                                valveOffset={offer.packagingFeature.vent && offer.packagingFeature.vent.location ? offer.packagingFeature.vent.location.value : null}
                                sideSealWidthId={offer.packagingFeature.sealWidth ? offer.packagingFeature.sealWidth.id : null}
                                roundedCornerId={offer.packagingFeature.roundedCorner ? offer.packagingFeature.roundedCorner.id : null}
                                doubleCutId={offer.packagingFeature.doubleCut ? offer.packagingFeature.doubleCut.id : null}
                                qrCodeTypeId={offer.packagingFeature.qrCodeType ? offer.packagingFeature.qrCodeType.id : null}
                                gussetTypeId={offer.packagingFeature.gussetType ? offer.packagingFeature.gussetType.id : null}
                                eyemarkId={offer.eyeMark ? offer.eyeMark.id : null}
                                bottomFillId={offer.bottomFill ? offer.bottomFill.id : null}
                                onInit={ctrl => { setDielineControl(ctrl); }}
                            />
                        </Card>
                        {offer.convertingMethod.componentType.id !== ComponentType.Rollstock &&
                            <Card title={<Translation id='a6c19a28-3460-4ce4-b500-364507f7a7d8' defaultMessage='Features' category='Card Title' />}>

                                <Row>
                                    {feature.zipper &&
                                        <Column>
                                            <SectionTitle offerKey={globalOfferId} cms='zipper' orderPlacementApi={orderPlacementApi} image='af959a05-b263-4695-8878-68a348b8585d'><Translation id='68e22377-f7f9-4796-ac80-23e42ae8bee1' defaultMessage='Zipper' category='Label' /></SectionTitle>
                                            <SectionLabel description={feature.zipper.value}>
                                                {feature.zipper.location &&
                                                    // <OffsetEditor title='Zipper' offset={feature.zipper.location} errorMessage={getFeatureError(ErrorCode.Feature.Zipper)} hasError={hasFeatureError(ErrorCode.Feature.Zipper)} onChanged={(value, uom) => { dielineControl.setZipper(feature.zipper.id, value); setFormValues('zipper', { value: value, uomId: uom }); }} />
                                                    feature.zipper.location.value
                                                }
                                            </SectionLabel>
                                        </Column>
                                    }
                                    {feature.tearNotch &&
                                        <Column>
                                            <SectionTitle offerKey={globalOfferId} cms='tear-notch' orderPlacementApi={orderPlacementApi} image='e41d1414-c1bd-4302-a410-b40a041d3c76'><Translation id='ab0dd63d-79c9-4b5f-bff1-8c6accf6b023' defaultMessage='Tear Notch' category='Label' /></SectionTitle>
                                            <SectionLabel description={feature.tearNotch.value}>
                                                {feature.tearNotch.location &&
                                                    // <OffsetEditor title='Tear Notch' offset={feature.tearNotch.location} errorMessage={getFeatureError(ErrorCode.Feature.TearNotch)} hasError={hasFeatureError(ErrorCode.Feature.TearNotch)} onChanged={(value, uom) => {dielineControl.setTearNotch(feature.tearNotch.id, value); setFormValues('tearNotch', { value: value, uomId: uom }); }} />
                                                    feature.tearNotch.location.value

                                                }
                                            </SectionLabel>
                                        </Column>

                                    }
                                </Row>
                                <Row>
                                    {
                                        feature.hangHole &&
                                        <Column>
                                            <SectionTitle offerKey={globalOfferId} cms='hang-hole' orderPlacementApi={orderPlacementApi} image='13306d0c-0ca7-4813-9397-9aca00719ee0'><Translation id='81702163-18c0-4de5-a792-6e6c98abdea8' defaultMessage='Hang Hole' category='Label' /></SectionTitle>
                                            <SectionLabel description={feature.hangHole.value}>
                                                {feature.hangHole.location &&
                                                    // <OffsetEditor title='Hang Hole' offset={feature.hangHole.location} errorMessage={getFeatureError(ErrorCode.Feature.HangHole)} hasError={hasFeatureError(ErrorCode.Feature.HangHole)} onChanged={(value, uom) => {dielineControl.setHangHole(feature.hangHole.id, value); setFormValues('hangHole', { value: value, uomId: uom });}} />
                                                    feature.hangHole.location.value

                                                }
                                            </SectionLabel>
                                        </Column>

                                    }
                                    {feature.vent &&
                                        <Column>
                                            <SectionTitle offerKey={globalOfferId} cms='valve' orderPlacementApi={orderPlacementApi} image='9da0c23f-6709-4d55-bb64-f1f09ee63284'><Translation id='47e24a00-6d3e-4141-b3c0-d95d425dee81' defaultMessage='Valve' category='Label' /></SectionTitle>
                                            <SectionLabel description={feature.vent.value}>
                                                {feature.vent.location &&
                                                    // <OffsetEditor title='Valve' offset={feature.vent.location} errorMessage={getFeatureError(ErrorCode.Feature.Valve)} hasError={hasFeatureError(ErrorCode.Feature.Valve)} onChanged={(value, uom) => {dielineControl.setValve(feature.vent.id, value);setFormValues('valve', { value: value, uomId: uom });}} />
                                                    feature.vent.location.value

                                                }
                                            </SectionLabel>
                                        </Column>
                                    }
                                </Row>
                            </Card>
                        }

                        <Card title={<Translation id='357a8a69-4bb5-4068-9541-89cb26b6d458' defaultMessage='Specifications' category='Card Title' />}>
                            <Row>
                                <Column>
                                    <SectionTitle offerKey={globalOfferId} cms='material' orderPlacementApi={orderPlacementApi}><Translation id='237f31ad-e94d-4024-92b0-83b915ec9c00' defaultMessage='Material' category='Label' /></SectionTitle>
                                    <SectionLabel description={'SPEC-' + offer.packagingMaterial.structureSpecId}><DownloadLink href={offer.packagingMaterial.pdfUrl}><Translation id='0717f632-37ca-4e42-832c-689ee4841fdb' defaultMessage='Information Sheet' category='Label' /></DownloadLink></SectionLabel>
                                </Column>
                                <Column>
                                    <SectionTitle offerKey={globalOfferId} cms='sku-count' orderPlacementApi={orderPlacementApi}><Translation id='b8b51cda-bc7c-4798-9b17-4534caa25aaa' defaultMessage='SKU Count' category='Label' /></SectionTitle>
                                    <SectionLabel description={offer.skuCount} />
                                </Column>
                            </Row>

                            <Row>
                                <Column>
                                    <SectionTitle offerKey={globalOfferId} cms='dimensions' orderPlacementApi={orderPlacementApi}><Translation id='82f3402f-e458-41bb-af02-7ab173565ee8' defaultMessage='Dimensions' category='Label' /></SectionTitle>
                                    <SectionLabel description={offerDataForLayout.formattedSizeString} />
                                </Column>
                                <Column>
                                    <SectionTitle offerKey={globalOfferId} cms='color-specification' orderPlacementApi={orderPlacementApi}><Translation id='a621f4d8-8783-4852-acb9-29007f7a3d0f' defaultMessage='Color Specification' category='Label' /></SectionTitle>
                                    <SectionLabel description={offer.colorSpec.value} />
                                </Column>
                            </Row>

                            {offer.convertingMethod.componentType.id !== ComponentType.Rollstock &&
                                <Row>
                                    <Column>
                                        <SectionTitle offerKey={globalOfferId} cms='rounded-corners' orderPlacementApi={orderPlacementApi} image='2c791cd2-b235-4f29-81c8-8ae84900aa75'><Translation id='7c696bb1-2fc7-477a-b9fe-ec25c674d91d' defaultMessage='Rounded Corners' category='Label' /></SectionTitle>
                                        <SectionLabel description={feature.roundedCorner ? feature.roundedCorner.value : '--'} />
                                    </Column>
                                    <Column>
                                        <SectionTitle offerKey={globalOfferId} cms='seal-width' orderPlacementApi={orderPlacementApi} image='debd99a5-6ad0-49fc-85c5-0b0e0af723ce'><Translation id='fbe5f534-ba05-4a65-b3cc-385b63e59254' defaultMessage='Seal Width' category='Label' /></SectionTitle>
                                        <SectionLabel description={feature.sealWidth ? feature.sealWidth.value : '--'} />
                                    </Column>
                                </Row>
                            }

                            <Row>
                                <Column>
                                    <SectionTitle offerKey={globalOfferId} cms='eyemark-placement' orderPlacementApi={orderPlacementApi}><Translation id='af463558-b3b7-4d24-a547-3764d1d7431c' defaultMessage='Eyemarks' category='Label' /></SectionTitle>
                                    <SectionLabel description={offer.eyeMark ? offer.eyeMark.value : '--'} />
                                </Column>
                                <Column>
                                    <SectionTitle offerKey={globalOfferId} cms='epac-connect' orderPlacementApi={orderPlacementApi}><Translation id='9d37c5ce-4a1e-4997-9bcb-2541ab4679bc' defaultMessage='ePac Connect' category='Label' /></SectionTitle>
                                    <SectionLabel description={feature.qrCodeType ? feature.qrCodeType.value : '--'} />
                                </Column>
                                <Column>
                                    {offer.core &&
                                        <div>
                                            <SectionTitle offerKey={globalOfferId} cms='core' orderPlacementApi={orderPlacementApi}><Translation id='5cbd9f74-2fbb-4e5f-a9eb-9f855a1af7b1' defaultMessage='Core' category='Label' /></SectionTitle>
                                            <SectionLabel description={offer.core ? offer.core.value : '--'} />
                                        </div>
                                    }

                                    {/*{offer.packagingFeature && offer.packagingFeature.doubleCut &&*/}
                                    {/*    <div>*/}
                                    {/*        <SectionTitle offerKey={globalOffer.globalOfferId} cms='doubleCut' orderPlacementApi={orderPlacementApi}><Translation id='3ecd3bd0-e6df-401e-9390-2a355378abb3' defaultMessage='Double Cut' category='Label' /></SectionTitle>*/}
                                    {/*        <SectionLabel description={offer.packagingFeature.doubleCut.value ? offer.packagingFeature.doubleCut.value : '--'} />*/}
                                    {/*    </div>*/}
                                    {/*}*/}

                                </Column>
                            </Row>
                        </Card>

                        <Card title={<Translation id='07f29958-5d6f-41e9-9f9f-b7ef4bc8417d' defaultMessage='Additional Manufacturing Instructions' category='Card Title' />}>
                            <SectionTitle offerKey={globalOfferId} cms='color-target-instructions' orderPlacementApi={orderPlacementApi}><Translation id='6449df23-7d25-4fd8-952f-94a74963ee75' defaultMessage='Color Instructions' category='Label' /></SectionTitle>
                            {offerDataForLayout?.orderIntent?.intentRequest?.colorTargetInstructions ?? "None"}
                        </Card>

                        {getBreakdown()
                            ? <Card title={<Translation id='ca5ca6f8-ef3c-4a03-b0a7-178b1eae2e0d' defaultMessage='SKU Descriptions and Pricing' category='Card Title' />} >
                                <Row>
                                    <Column width="4">
                                        <SectionTitle><Translation id='6c472341-a5f6-4f1b-994e-8f2dfa7da132' defaultMessage='SKU Description(s)' category='Label' /></SectionTitle>
                                    </Column>
                                    <Column width="4">
                                        <SectionTitle><Translation id='5e4f786d-029d-4464-8911-dcb3db5bab93' defaultMessage='Unit Price' category='Label' /></SectionTitle>
                                    </Column>
                                    <Column width="4">
                                        <SectionTitle><Translation id='6c6df362-bf55-4248-8e0a-4af3eb5c99d4' defaultMessage='Quantity' category='Label' /></SectionTitle>
                                    </Column>
                                    <Column width="4">
                                        <SectionTitle><Translation id='9b649da5-da5c-4ac3-aefe-73d5cf26ca47' defaultMessage='Calculated Price' category='Card Title' /></SectionTitle>
                                    </Column>
                                </Row>
                                {offer.checkoutDetails.skuLineItems &&
                                    offer.checkoutDetails.skuLineItems.map((sku, idx) => {
                                        return (
                                            <Row key={idx}>
                                                <Column width="4">
                                                    <span>{sku.description}</span>
                                                </Column>
                                                <Column width="4" className="unitPrice">
                                                    <span><Money currency={offerCurrency} amount={sku.unitPrice.amount} /></span>
                                                </Column>
                                                <Column width="4" className="quantity">
                                                    <span className="currency-amount">{formatQuantity(sku.quantity)}</span>
                                                </Column>
                                                <Column className="price" width="4">
                                                    <span><Money currency={offerCurrency} amount={FormatProvider.FormatDecimalWithCommas((sku.quantity * sku.unitPrice.amount), 2)} /></span>
                                                </Column>
                                            </Row>
                                        )
                                    })
                                }
                                {orderIntent &&
                                    orderIntent.skuLineItems.map((sku, idx) => {
                                        return (
                                            <Row key={idx}>
                                                <Column width="4">
                                                    <span>{sku.description}</span>
                                                </Column>
                                                <Column width="4" className="unitPrice">
                                                    <span><Money currency={offerCurrency} amount={sku.unitPrice} /></span>
                                                </Column>
                                                <Column width="4" className="quantity">
                                                    <span className="currency-amount">{formatQuantity(sku.quantity)}</span>
                                                </Column>
                                                <Column className="price" width="4">
                                                    <span><Money currency={offerCurrency} amount={FormatProvider.FormatDecimalWithCommas((sku.quantity * sku.unitPrice), 2)} /></span>
                                                </Column>
                                            </Row>
                                        )
                                    })
                                }
                                <Row>
                                    <Column />
                                </Row>
                                <Row>
                                    <Column width="4">
                                        &nbsp;
                                    </Column>
                                    <Column width="4">
                                        &nbsp;
                                    </Column>
                                    <Column width="4">
                                        <Translation id='8bb80a73-14d0-4014-96b8-f3c0a73135a5' defaultMessage='Subtotal' category='Label' />
                                    </Column>
                                    <Column width="4">
                                        <Money currency={offerCurrency} amount={FormatProvider.FormatDecimalWithCommas(getTotals().subTotal, 2)} />
                                    </Column>
                                </Row>

                                <Row>
                                    <Column />
                                </Row>

                                {getTotals().connect > 0 &&
                                    <Row>
                                        <Column width="4">
                                            &nbsp;
                                        </Column>
                                        <Column width="4">
                                            &nbsp;
                                        </Column>
                                        <Column width="4">
                                            <ColumnTitle cms='order-summary-connect' orderPlacementApi={orderPlacementApi} offerKey={globalOfferId} id="ePac Connect" smallIcon={true}><Translation id='8641d8ce-882c-4612-adee-926ff16c1f4c' defaultMessage='Connect' category='Label' /></ColumnTitle>
                                        </Column>
                                        <Column width="4">
                                            <Money currency={offerCurrency} amount={FormatProvider.FormatDecimalWithCommas(getTotals().connect, 2)} />
                                        </Column>
                                    </Row>
                                }

                                {getTotals().overage > 0 &&
                                    <Row>
                                        <Column width="4">
                                            &nbsp;
                                        </Column>
                                        <Column width="4">
                                            &nbsp;
                                        </Column>
                                        <Column width="4">
                                            <ColumnTitle
                                                offerKey={globalOfferId}
                                                cms="core"
                                                orderPlacementApi={orderPlacementApi}
                                                onShowHelp={() => {
                                                    // gaContext.sendHelpClick("Core");
                                                }}
                                            >
                                                <Translation id='ede6f5db-ad17-4dfa-b60a-6c48e656f515' defaultMessage='Overage' category='Label' />
                                            </ColumnTitle>
                                        </Column>
                                        <Column width="4">
                                            <Money currency={offerCurrency} amount={FormatProvider.FormatDecimalWithCommas(getTotals().overage, 2)} />
                                        </Column>
                                    </Row>
                                }

                                {getTotals().shipping > 0 &&
                                    <Row>
                                        <Column width="4">
                                            &nbsp;
                                        </Column>
                                        <Column width="4">
                                            &nbsp;
                                        </Column>
                                        <Column width="4">
                                            <ColumnTitle
                                                offerKey={globalOfferId}
                                                cms="core"
                                                orderPlacementApi={orderPlacementApi}
                                                onShowHelp={() => {
                                                    // gaContext.sendHelpClick("Core");
                                                }}
                                            >
                                                <Translation id='b0bb9d47-0aca-4f28-9a5e-6c159a12fdb9' defaultMessage='Shipping' category='Label' />
                                            </ColumnTitle>
                                        </Column>
                                        <Column width="4">
                                            <Money currency={offerCurrency} amount={FormatProvider.FormatDecimalWithCommas(getTotals().shipping, 2)} />
                                        </Column>
                                    </Row>
                                }

                                <Row>
                                    <Column />
                                </Row>

                                <Row>
                                    <Column width="4">
                                        &nbsp;
                                    </Column>
                                    <Column width="4">
                                        &nbsp;
                                    </Column>
                                    <Column width="4">
                                        <Translation id='45178e09-c110-485f-8f1b-0b16e7331cd8' defaultMessage='Total' category='Label' />
                                    </Column>
                                    <Column width="4">
                                        {getTotals().totalAmountDue > 0
                                            ? <Money currency={offerCurrency} amount={FormatProvider.FormatDecimalWithCommas(getTotals().totalAmountDue, 2)} />
                                            : <Money currency={offerCurrency} amount={FormatProvider.FormatDecimalWithCommas(0, 2)} />
                                        }
                                    </Column>
                                </Row>

                                <Row>
                                    <Column />
                                </Row>

                                {getTotals().totalAvailableCreditAmount < 0 &&
                                    <Row>
                                        <Column width="4">
                                            &nbsp;
                                        </Column>
                                        <Column width="4">
                                            &nbsp;
                                        </Column>
                                        <Column width="4">
                                            <Translation id='bfeafeb0-6330-4a17-8144-987c716d7830' defaultMessage='Credit Applied' category='Label' />
                                        </Column>
                                        <Column width="4">
                                            {getTotals().creditAppliedAmount < 0
                                                ? <Money currency={offerCurrency} amount={FormatProvider.FormatDecimalWithCommas(getTotals().totalAvailableCreditAmount, 2)} />
                                                : <Money currency={offerCurrency} amount={FormatProvider.FormatDecimalWithCommas(0, 2)} />
                                            }

                                        </Column>
                                    </Row>}

                                {getTotals().refundDueAmount < 0 &&
                                    <Row>
                                        <Column width="4">
                                            &nbsp;
                                        </Column>
                                        <Column width="4">
                                            &nbsp;
                                        </Column>
                                        <Column width="4">
                                            <Translation id='ca92b628-dd6d-47cf-9fa4-b908c6d8f510' defaultMessage='Refund Due' category='Label' />
                                        </Column>
                                        <Column width="4">
                                            {getTotals().creditAppliedAmount < 0
                                                ? <Money currency={offerCurrency} amount={FormatProvider.FormatDecimalWithCommas(getTotals().refundDueAmount * -1, 2)} />
                                                : <Money currency={offerCurrency} amount={FormatProvider.FormatDecimalWithCommas(0, 2)} />
                                            }

                                        </Column>
                                    </Row>}

                                <Row>
                                    <Column width="4">
                                        &nbsp;
                                    </Column>
                                    <Column width="4">
                                        &nbsp;
                                    </Column>
                                    <Column width="4">
                                        <span className='color-orange'><Translation id='ff2dd4bf-ae11-4171-9a5e-da87886f7240' defaultMessage='Your Payment' category='Label' /></span>
                                    </Column>
                                    <Column width="4">
                                        {getTotals().partialPayment > 0
                                            ? <Money currency={offerCurrency} amount={FormatProvider.FormatDecimalWithCommas(getTotals().partialPayment, 2)} />
                                            : <Money currency={offerCurrency} amount={FormatProvider.FormatDecimalWithCommas(0, 2)} />
                                        }

                                    </Column>
                                </Row>

                                {getTotals().estimatedOverage && getTotals().estimatedOverage > 0 ?
                                    <Row>
                                        <Column width="4">
                                            &nbsp;
                                        </Column>
                                        <Column width="4">
                                            &nbsp;
                                        </Column>
                                        <Column width="4">
                                            <span className='color-orange'><Translation id='21a9604b-fcac-4503-a8c0-e332a0dfbb3c' defaultMessage='Estimated Overage' category='Label' /></span>
                                        </Column>
                                        <Column width="4">
                                            <Money currency={offerCurrency} amount={FormatProvider.FormatDecimalWithCommas(getTotals().estimatedOverage, 2)} />
                                        </Column>
                                    </Row>
                                    : ''
                                }

                                {getTotals().estimatedShipping && getTotals().estimatedShipping > 0 && !offer.checkoutDetails.plantPickup ?
                                    <Row>
                                        <Column width="4">
                                            &nbsp;
                                        </Column>
                                        <Column width="4">
                                            &nbsp;
                                        </Column>
                                        <Column width="4">
                                            <span className='color-orange'><Translation id='bd534260-1a26-42a4-9c7a-8ba0401bf8eb' defaultMessage='Estimated Shipping' category='Label' /></span>
                                        </Column>
                                        <Column width="4">
                                            {getTotals().estimatedShipping > 0
                                                ? <Money currency={offerCurrency} amount={FormatProvider.FormatDecimalWithCommas(getTotals().estimatedShipping, 2)} />
                                                : <span>TBD</span>
                                            }
                                        </Column>
                                    </Row>
                                    : ''
                                }


                            </Card>





                            : <Card title="SKU Descriptions and Pricing" defaultMessage="Uploaded Artwork*" >
                                <Row>
                                    <Column width="4">
                                        <SectionTitle><Translation id='6c472341-a5f6-4f1b-994e-8f2dfa7da132' defaultMessage='SKU Description(s)' category='Label' /></SectionTitle>
                                    </Column>
                                    <Column width="4">
                                        <SectionTitle><Translation id='5e4f786d-029d-4464-8911-dcb3db5bab93' defaultMessage='Unit Price' category='Label' /></SectionTitle>
                                    </Column>
                                    <Column width="4">
                                        <SectionTitle><Translation id='6c6df362-bf55-4248-8e0a-4af3eb5c99d4' defaultMessage='Quantity' category='Label' /></SectionTitle>
                                    </Column>
                                    <Column width="4">
                                        <SectionTitle><Translation id='5b181866-087c-4e41-b509-0f02f16d8ccd' defaultMessage='Calculated Price' category='Label' /></SectionTitle>
                                    </Column>
                                </Row>
                                {offer.checkoutDetails.skuLineItems &&
                                    offer.checkoutDetails.skuLineItems.map((sku, idx) => {
                                        return (
                                            <Row key={idx}>
                                                <Column width="4">
                                                    <span>{sku.description}</span>
                                                </Column>
                                                <Column width="4" className="unitPrice">
                                                    <span><Money currency={offerCurrency} amount={sku.unitPrice.amount} /></span>
                                                </Column>
                                                <Column width="4" className="quantity">
                                                    <span className="currency-amount">{formatQuantity(sku.quantity)}</span>
                                                </Column>
                                                <Column className="price" width="4">
                                                    <span><Money currency={offerCurrency} amount={FormatProvider.FormatDecimalWithCommas((sku.quantity * sku.unitPrice.amount), 2)} /></span>
                                                </Column>
                                            </Row>
                                        )
                                    })
                                }
                                <Row>
                                    <Column />
                                </Row>
                                <Row>
                                    <Column width="4">
                                        &nbsp;
                                    </Column>
                                    <Column width="4">
                                        &nbsp;
                                    </Column>
                                    <Column width="4">
                                        Subtotal
                                    </Column>
                                    <Column width="4">
                                        <Money currency={offerCurrency} amount={FormatProvider.FormatDecimalWithCommas(getTotals().subTotal, 2)} />
                                    </Column>
                                </Row>
                                <Row>
                                    <Column width="4">
                                        &nbsp;
                                    </Column>
                                    <Column width="4">
                                        &nbsp;
                                    </Column>
                                    <Column width="4">
                                        <ColumnTitle cms='order-summary-overage' orderPlacementApi={orderPlacementApi} offerKey={globalOfferId} id="Overage" smallIcon={true}>Overage</ColumnTitle>

                                    </Column>
                                    <Column width="4">
                                        <Money currency={offerCurrency} amount={FormatProvider.FormatDecimalWithCommas(getTotals().overage, 2)} />
                                    </Column>
                                </Row>
                                <Row>
                                    <Column width="4">
                                        &nbsp;
                                    </Column>
                                    <Column width="4">
                                        &nbsp;
                                    </Column>
                                    <Column width="4">
                                        <ColumnTitle cms='order-summary-shipping' orderPlacementApi={orderPlacementApi} offerKey={globalOfferId} id="Shipping" smallIcon={true}>Shipping</ColumnTitle>
                                    </Column>
                                    <Column width="4">
                                        {getTotals().shipping > 0
                                            ? <Money currency={offerCurrency} amount={FormatProvider.FormatDecimalWithCommas(getTotals().shipping, 2)} />
                                            : <Money currency={""} amount={"TBD"} />
                                        }
                                    </Column>
                                </Row>
                                <Row>
                                    <Column width="4">
                                        &nbsp;
                                    </Column>
                                    <Column width="4">
                                        &nbsp;
                                    </Column>
                                    <Column width="4">
                                        Estimated Total
                                    </Column>
                                    <Column width="4">
                                        <Money currency={offerCurrency} amount={FormatProvider.FormatDecimalWithCommas(getTotals().totalAmountDue, 2)} />
                                    </Column>
                                </Row>
                            </Card>
                        }


                        <Card title={<Translation id='e37ac2e3-07fe-4531-a998-8dd5e83f23a8' defaultMessage='Uploaded Artwork' category='Card Title' />}>
                            <Row>
                                <Column>
                                    <SectionTitle><Translation id='6c472341-a5f6-4f1b-994e-8f2dfa7da132' defaultMessage='SKU Description(s)' category='Label' /></SectionTitle>
                                </Column>
                                <Column>
                                    <SectionTitle><Translation id='147e4a72-e77f-460f-ba20-a38f6ae52422' defaultMessage='Artwork Download' category='Label' /></SectionTitle>
                                </Column>
                            </Row>
                            {artworkSkus && artworkSkus.length > 0 ?
                                artworkSkus.map((sku, idx) => {
                                    return (
                                        <Row key={idx}>
                                            <Column>
                                                {sku.skuDescription}
                                            </Column>
                                            <Column>
                                                <DownloadLink href={sku.location}>{sku.skuDescription}</DownloadLink>
                                            </Column>
                                        </Row>
                                    )
                                })
                                : ''
                            }
                            {offerContext.reorderDetailsCheckout?.artworkApprovalWaived &&
                                <div className="artwork-waived-info">
                                    Artwork approval has been waived on {new Date(offerContext.reorderDetailsCheckout.artworkApprovalWaived.dateTime).toUTCString()} by user {offerContext.reorderDetailsCheckout.artworkApprovalWaived.emailAddress}
                                </div>
                            }
                        </Card>
                    </Layout>
                </div>
            )
        }
    }

    return (

        loaded && (
            <div>
                {render()}
            </div>)
    );
}
import React from "react";
import { MessageSquare } from "react-feather";

export const MessageLink = ({ type, onClick, children }) => {
    const displayName = MessageLink.name;

    const getClassName = () => {
        let result = "iconLink";

        switch (type) {
            case "warn":
                result += " warn";
                break;
            case "error":
                result += " error";
                break;
        }
        return result;
    };

    return (
        <div className={getClassName()}>
            <span>
                <MessageSquare className="icon" onClick={onClick} />
                <span onClick={onClick}>{children}</span>
            </span>
        </div>
    );
};

import React from "react";
import { Edit } from "react-feather";

export const EditLink = ({ onClick, disabled, children }) => {
    const displayName = EditLink.name;

    return (
        <div className={disabled ? "iconLink disabled" : "iconLink"}>
            {disabled ? (
                <span>{children}</span>
            ) : (
                <span onClick={onClick}>
                    <Edit className="icon" />
                    {children}
                </span>
            )}
        </div>
    );
};

import React, { useState, useEffect, useRef } from "react";
import { Image } from 'react-feather';
import { Row } from "../../core/Row";
import { Column } from "../../core/Column";
import { SectionTitle } from "../../core/SectionTitle";
import { Money } from "./Money";
import { FormatProvider } from "../../core/offer/FormatProvider";
import { Translation } from "../../core/Translation";
import { Popup } from "./Popup";
import { ImageViewer } from "../ImageViewer";

import "./SkuEditor.css";

export class Sku {
    constructor(quantity, unitPrice, description, poNumber) {
        this.quantity = quantity;
        this.unitPrice = unitPrice;
        this.description = description;
        this.poNumber = poNumber;
    }

    get totalPrice() {
        return this.quantity * this.unitPrice;
    }
}

export const SkuEditor = ({
    offerInfo,
    register,
    skus,
    orderPlacementApi,
    errors,
    getErrorMessage,
    setValue,
    append,
    watch,
    control,
    clearErrors,
    getValues,
    setError,
    trigger,
    trackEvent,
    analyticsMetadata,
}) => {

    const popup = useRef(null);
    const [initialized, setInitialized] = useState(false);
    const [popupImageUrl, setPopupImageUrl] = useState(false);
    const testRoll = offerInfo?.testRollType;
    
    const key = offerInfo.key;
    const restrictions =
        offerInfo && offerInfo.checkoutDetails && offerInfo.checkoutDetails.checkoutRestrictions
            ? offerInfo.checkoutDetails.checkoutRestrictions
            : null;

    const offerHasOutsourcingCost =
        !offerInfo.isPressProof &&
        offerInfo.packagingFeature &&
        offerInfo.packagingFeature.outsourcedFeatures &&
        offerInfo.packagingFeature.outsourcedFeatures.length > 0;

    const isReorder = !!offerInfo.reorderDetails;
    const priceColumnWidth = isReorder ? 5 : 4; // reorder case has thumbnail shown in the table so columns must be smaller

    const distribute = (quantity, skuCount) => {
        let result = [];
        let divident = Math.floor(quantity / skuCount);
        let remainder = quantity % skuCount;
        for (var i = 0; i < skuCount; i++) {
            result.push(i < remainder ? divident + 1 : divident);
        }
        return result;
    };

    const getQuantityBreaks = (allQuantityBreaks) => {
        let result = [];
        allQuantityBreaks.forEach((b) => {
            let quantityBreak = {
                quantity: parseInt(b.quantityBreak),
                unitPrice: {
                    amount: offerHasOutsourcingCost ? b.outsourcing.totalUnitPrice.amount : b.price.unitPrice.amount,
                    currency: b.price.unitPrice.currency,
                },
            };
            result.push(quantityBreak);
        });
        return result;
    };

    const createSkuCollection = (quantityBreak) => {
        let result = [];
        let skuQuantities = distribute(quantityBreak.quantity, offerInfo.skuCount);
        let i = 0;

        skuQuantities.forEach((skuQuantity) => {
            i++;
            let item = new Sku(skuQuantity, quantityBreak.unitPrice.amount, "", null);
            result.push(item);
        });

        return result;
    };

    const [quantityBreaks, setQuantityBreaks] = useState(function init() {
        return getQuantityBreaks(offerInfo.calculationBreakdown);
    });

    const [activeQuantityBreak, setActiveQuantityBreak] = useState(function init() {
        quantityBreaks[0].unitPrice.amount = parseFloat(quantityBreaks[0].unitPrice.amount);
        return quantityBreaks[0];
    });



    const getTotalQuantityForAllLineItems = (skusToSum) => {
        var total = 0;
        if (skusToSum) {
            total = getTotalForSkus(skusToSum);
        } else {
            total = getTotalForSkus(getValues("skuLineItems"));
        }
        console.log("getTotalQuantityForAllLineItems total", total);

        return total;
    };

    const recalculateTotal = (skusToSum) => {
        var total = getTotalQuantityForAllLineItems(skusToSum);

        setValue("skuTotal", total);

        if (parseInt(total) < parseInt(quantityBreaks[0].quantity) && !testRoll) {
            setError("badTotal", {
                type: "custom",
                message: "The total quantity of all SKUs must exceed " + quantityBreaks[0].quantity + ".",
            });
        } else {
            clearErrors("badTotal");
        }
    };

    const setErrorIfBelowAbsoluteMinimum = (total) => {
        if (parseInt(total) < parseInt(quantityBreaks[0].quantity) && !testRoll) {
            setError("badTotal", {
                type: "custom",
                message: "The total quantity of all SKUs must exceed " + quantityBreaks[0].quantity,
            });
        } else {
            clearErrors("badTotal");
        }
    };


    const onSkuLineItemQuantityChanged = async (quantity, index) => {
        var total = getTotalQuantityForAllLineItems();
        setValue("skuTotal", total);
        setErrorIfBelowAbsoluteMinimum(total);
        await trigger(`skuLineItems.${index}.quantity`);
        //setErrorIfSkuBelowMin();
        trackEvent({
            event: "ordering_tool_interactions",
            eventProps: {
                step: "placement_2",
                product_category: analyticsMetadata.industryName,
                product_sub_category: analyticsMetadata.industryCategoryName,
                selection: `sku_${index}_quantity`,
                element: quantity,
            },
            userId: analyticsMetadata?.visitorId,
        });
    };

    const getTotalForSkus = (skusToSum) => {
        var skuTotal = 0;
        if (skusToSum) {
            skusToSum.forEach((sku) => {
                skuTotal += parseInt(sku.quantity) ? parseInt(sku.quantity) : 0;
            });
        }
        return skuTotal;
    };

    useEffect(() => {
        if (initialized){
            return;
        }
        console.group("%c SkuEditor initialize START", "color: #ff7f5d");

        var quantityBreaks = getQuantityBreaks(offerInfo.calculationBreakdown);
        let skuLineItems = [];

        if (!skus || skus.length === 0) {
            //Initializing for the first time
            console.log("skus were not found");
            if (testRoll) {
                append({
                    description: "Test Roll",
                    quantity: 1,
                    unitPrice: getTotalPrice()
                })
                setValue("skuUnitPrice", getTotalPrice());
            }
            else if (isReorder) {
                skuLineItems = getReorderSkuList(quantityBreaks[0].unitPrice.amount);
                append(skuLineItems);
                setValue("skuUnitPrice", parseFloat(quantityBreaks[0].unitPrice.amount));
            }
            else {
                var collection = createSkuCollection(quantityBreaks[0]);
                collection.forEach((sku, idx) => {
                    skuLineItems.push({
                        description: "",
                        quantity: null,
                        unitPrice: sku.unitPrice
                    });
                });
                append(skuLineItems);
                setValue("skuUnitPrice", parseFloat(quantityBreaks[0].unitPrice.amount));

                recalculateTotal(skuLineItems);
            }
        } else {
            console.log("skus were found", skus);
            if (testRoll) {
                setValue("skuUnitPrice", getTotalPrice());
            }
            else {
                setValue("skuUnitPrice", parseFloat(getValues("skuLineItems.0.unitPrice")));
            }
            recalculateTotal(skus);
        }
        console.log("%c SkuEditor initialize END", "color: #ff7f5d");
        console.groupEnd();
        setInitialized(true);
    }, []);

    const getMatchingQuantityBreak = (qty) => {
        let result = quantityBreaks[0];
        quantityBreaks.forEach((br) => {
            if (qty >= br.quantity) {
                result = br;
            }
        });
        return result;
    };

    const getCurrency = () => quantityBreaks[0].unitPrice.currency;
    const getMaterialLength = () => offerInfo.calculationBreakdown[0]?.filmLength?.value;
    const getLengthUOM = () => offerInfo.calculationBreakdown[0]?.filmLength?.unit ? offerInfo.calculationBreakdown[0]?.filmLength?.unit : " ft";
    const getTotalPrice = () => offerInfo.calculationBreakdown[0].price.totalPrice.amount;


    const getReorderSkuList = (unitPrice) => {
        var list = [];
        if (offerInfo.reorderDetails.skus) {
            offerInfo.reorderDetails.skus.forEach((sku) => {
                list.push({
                    approvedSkuId: sku.approvedSkuId,
                    description: sku.description,
                    quantity: null,
                    unitPrice: unitPrice,
                    thumbnail: sku.thumbnail
                })
            });
        }
        return list;
    }

    const skuQuantityTotalWatch = watch("skuTotal");
    const skuUnitPriceWatch = watch("skuUnitPrice");
    const skuTotals = getValues("skuTotal");

    useEffect(() => {
        if (!initialized) {
            return;
        }
        console.log("Whoa, sku total changed", skuTotals);
        var parsedTotals = parseInt(getValues("skuTotal"));

        var newMatchingQuantityBreak = getMatchingQuantityBreak(parsedTotals);
        console.log(
            "old quantity break, new quantity break",
            activeQuantityBreak.quantity,
            newMatchingQuantityBreak.quantity
        );
        if (newMatchingQuantityBreak.quantity !== activeQuantityBreak.quantity) {
            console.log(
                "quantity now exceeds the active quantity break, so here's the new one",
                newMatchingQuantityBreak
            );
            for (let index in skus) {
                setValue(`skuLineItems.${index}.unitPrice`, parseFloat(newMatchingQuantityBreak.unitPrice.amount));
            }
            setValue("skuUnitPrice", parseFloat(newMatchingQuantityBreak.unitPrice.amount));
            setActiveQuantityBreak(newMatchingQuantityBreak);
        }
    }, [skuTotals]);


    const getLineItemTotalPrice = (unitPrice, quantity) => {
        var total = (parseFloat(unitPrice) * parseInt(quantity)).toFixed(2);
        return isNaN(total) ? 0 : total;
    };

    const showImage = (imageUrl) => {
        setPopupImageUrl(imageUrl);
        popup.current.show();
    };

    return (
        <div className="skuEditor">
            <input type="hidden" {...register("skuUnitPrice")} />
            {skus && (
                <div>

                    <Row className="title-row">
                        <Column width={3} className="description">
                            <SectionTitle
                                offerKey={key}
                                required
                                cms="sku-description"
                                orderPlacementApi={orderPlacementApi}
                                imageUrl="/images/popup/default_popup.png"
                                onShowHelp={() => {
                                    // gaContext.sendHelpClick("SKU Description");
                                }}
                            >
                                <Translation
                                    id="40a94643-b358-42d9-b832-4ae33401bc90"
                                    defaultMessage="SKU Description"
                                    category="Label"
                                />
                            </SectionTitle>
                        </Column>
                        {isReorder &&
                            <div className="thumbnail">
                                &nbsp;
                             </div>
                        }
                        {!testRoll && <Column width={priceColumnWidth} className="unitPrice">
                            <SectionTitle
                                offerKey={key}
                                cms="unit-price"
                                orderPlacementApi={orderPlacementApi}
                                imageUrl="/images/popup/default_popup.png"
                                onShowHelp={() => {
                                    // gaContext.sendHelpClick("Unit Price");
                                }}
                            >
                                <Translation
                                    id="5e4f786d-029d-4464-8911-dcb3db5bab93"
                                    defaultMessage="Unit Price"
                                    category="Label"
                                />
                            </SectionTitle>
                        </Column>}
                        {testRoll && <Column width={4} className="materialLength">
                            <SectionTitle
                                offerKey={key}
                                cms="material-length"
                                orderPlacementApi={orderPlacementApi}
                                imageUrl="/images/popup/default_popup.png"
                                onShowHelp={() => {

                                }}
                            >
                                <Translation id='42664f74-d7f0-403f-9497-46d973a9c649' defaultMessage='Material Length' category='Label' />
                            </SectionTitle>
                        </Column>}
                        <Column width={6} className="quantity">
                            <SectionTitle
                                required
                                offerKey={key}
                                cms="quantity"
                                orderPlacementApi={orderPlacementApi}
                                imageUrl="/images/popup/default_popup.png"
                                onShowHelp={() => {
                                    // gaContext.sendHelpClick("Quantity");
                                }}
                            >
                                <Translation
                                    id="6c6df362-bf55-4248-8e0a-4af3eb5c99d4"
                                    defaultMessage="Quantity"
                                    category="Label"
                                />
                            </SectionTitle>
                        </Column>
                        <Column width={priceColumnWidth} className="price">
                            <SectionTitle
                                offerKey={key}
                                cms="price"
                                orderPlacementApi={orderPlacementApi}
                                imageUrl="/images/popup/default_popup.png"
                                onShowHelp={() => {
                                    // gaContext.sendHelpClick("Total Price");
                                }}
                            >
                                <Translation
                                    id="3598c25f-9b19-40e3-bf90-1c5c81f222cd"
                                    defaultMessage="Price"
                                    category="Label"
                                />
                            </SectionTitle>
                        </Column>
                    </Row>
                    {skus.map((field, index) => {
                        return (
                            <Row className="sku-row" key={"sku-row" + index}>
                                <Column width={3} className="description">
                                    <div className="input-validation">
                                        <div className="sku-description">
                                            <input
                                                data-testid={"order-placement-sku-breakdown-description"}
                                                key={field.id}
                                                className={`control textbox ${getErrorMessage(errors, `skuLineItems.${index}.description`)?.message
                                                    ? "error"
                                                    : null
                                                    }`}
                                                //ref={register}
                                                disabled={testRoll}
                                                placeholder={!testRoll ? "Sku description" : null}
                                                {...register(`skuLineItems.${index}.description`, {
                                                    required: {
                                                        value: true,
                                                        message: "Sku description is required",
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: "Sku length cannot exceed 100 characters",
                                                    },
                                                    pattern: {
                                                        value: /^[^\\|/|:|*|?|"|<|>||(|)|^|||&|%|!|@|#|$|(]*$/,
                                                        message:
                                                            'Sku descriptions cannot contain the following characters: ! @ # $ % ^ & * ( )  / : * ? " < > |',
                                                    },
                                                    onBlur: (e) => {
                                                        trackEvent({
                                                            event: "ordering_tool_interactions",
                                                            eventProps: {
                                                                step: "placement_2",
                                                                product_category: analyticsMetadata.industryName,
                                                                product_sub_category:
                                                                    analyticsMetadata.industryCategoryName,
                                                                selection: `sku_${index}_description`,
                                                                element: e.target.value,
                                                            },
                                                            userId: analyticsMetadata?.visitorId,
                                                        });
                                                    },
                                                })}
                                            />
                                        </div>
                                        {getErrorMessage(errors, `skuLineItems.${index}.description`) && (
                                            <span className="error-message">
                                                {getErrorMessage(errors, `skuLineItems.${index}.description`)?.message}
                                            </span>
                                        )}
                                    </div>
                                </Column>
                                {isReorder &&
                                    <div className="thumbnail">
                                        <img width="100%" src={field.thumbnail} onClick={() => { showImage(field.thumbnail) }} />
                                    </div>
                                }
                                {!testRoll &&
                                    <Column width={priceColumnWidth} className="unitPrice">
                                        {/* <Money currency={getCurrency()} amount={field.unitPrice.toFixed(4)} /> */}
                                        <div className="money">
                                            <span className="currency color-light-grey">{getCurrency()}</span>
                                            <span className="amount color-grey">{skuUnitPriceWatch}</span>
                                            <input
                                                type="hidden"
                                                className="amount color-grey"
                                                readOnly
                                                {...register(`skuLineItems.${index}.unitPrice`)}
                                            />
                                        </div>
                                    </Column>
                                }
                                {testRoll &&
                                    <Column width={4} className="materialLength">
                                        <span className="amount color-grey">
                                            {FormatProvider.FormatNumberWithCommas(getMaterialLength())}
                                            {getLengthUOM()}
                                        </span>
                                    </Column>
                                }
                                <Column width={6} className="quantity">
                                    <div className="input-validation">
                                        <input
                                            type="number"
                                            onWheel={e => {
                                                e.target.blur();
                                            }}
                                            data-testid={"order-placement-sku-breakdown-quantity"}
                                            disabled={testRoll}
                                            className={`control textbox ${getErrorMessage(errors, `skuLineItems.${index}.quantity`)?.message
                                                ? "error"
                                                : null
                                                }`}
                                            {...register(`skuLineItems.${index}.quantity`, {
                                                required: {
                                                    value: true,
                                                    message: "Sku quantity is required and must be greater than zero",
                                                },
                                                maxLength: {
                                                    value: 10,
                                                    message: "Sku quantity cannot exceed 10 digits",
                                                },
                                                pattern: {
                                                    value: /^\d+$/,
                                                    message: "Sku quantity must be numeric",
                                                },
                                                onChange: async (e) => await onSkuLineItemQuantityChanged(e.target.value, index),
                                                validate: {
                                                    positive: (v) => {
                                                        if (v > 0) {
                                                            return true;
                                                        }
                                                        return "Must be greater than zero"
                                                    },
                                                    quantityCheck: (v) => {
                                                        if (
                                                            restrictions &&
                                                            restrictions.minSkuQuantity &&
                                                            v < restrictions.minSkuQuantity
                                                        ) {
                                                            return `SKU quantity cannot be be less than ${restrictions.minSkuQuantity}`;
                                                        }

                                                        if (
                                                            restrictions &&
                                                            restrictions.maxSkuQuantity &&
                                                            v > restrictions.maxSkuQuantity
                                                        ) {
                                                            return `SKU quantity cannot be greater than ${restrictions.maxSkuQuantity}`;
                                                        }
                                                        return true;
                                                    },
                                                }
                                            })}
                                        />
                                        {getErrorMessage(errors, `skuLineItems.${index}.quantity`) && (
                                            <span className="error-message">
                                                {getErrorMessage(errors, `skuLineItems.${index}.quantity`)?.message}
                                            </span>
                                        )}
                                    </div>
                                </Column>
                                <Column width={priceColumnWidth} className="price">
                                    <Money
                                        currency={getCurrency()}
                                        amount={FormatProvider.FormatDecimalWithCommas(
                                            getLineItemTotalPrice(
                                                getValues(`skuLineItems.${index}.unitPrice`),
                                                getValues(`skuLineItems.${index}.quantity`),
                                                2
                                            ), 2)}
                                    />
                                </Column>
                            </Row>
                        );
                    })}
                    {getErrorMessage(errors, "badTotal") && (
                        <Row>
                            <Column width={3} className="description">
                                &nbsp;
                            </Column>
                            <Column width={4} className="description">
                                &nbsp;
                            </Column>
                            <Column width={6} className="description">
                                <div className="error-message">{getErrorMessage(errors, "badTotal")?.message}</div>
                            </Column>
                            <Column width={4} className="description">
                                &nbsp;
                            </Column>
                        </Row>
                    )}
                    {!testRoll && <Row className="totals-row">
                        <Column width={3} className="description">
                            &nbsp;
                        </Column>
                        {isReorder &&
                            <div className="thumbnail">
                                &nbsp;
                            </div>
                        }
                        <Column width={priceColumnWidth} className="unitPrice">
                            <span>
                                <Translation
                                    id="45178e09-c110-485f-8f1b-0b16e7331cd8"
                                    defaultMessage="Total"
                                    category="Label"
                                />
                            </span>
                        </Column>
                        <Column width={6} className="quantity">
                            <span className="amount color-grey">
                                {FormatProvider.FormatNumberWithCommas(skuQuantityTotalWatch)}
                            </span>
                        </Column>
                        <Column width={priceColumnWidth} className="price">
                            <Money
                                currency={getCurrency()}
                                amount={FormatProvider.FormatDecimalWithCommas(
                                    (parseFloat(skuUnitPriceWatch) * parseInt(skuQuantityTotalWatch)).toFixed(2),
                                    2
                                )}
                            />
                        </Column>
                    </Row>
                    }
                    <Popup closeOnClickAway={true} ref={popup}>
                        <ImageViewer src={popupImageUrl} src2='/images/sku.png' />
                    </Popup>
                </div>
            )}


        </div>
    );
};
